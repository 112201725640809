import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from "../Button"
import Attention from "../../images/sun.svg"
import { calculateTDEE } from "./WeightLossForm"
import { PortalContext } from '../../AuthenticatedUser'
import EditableItemsCard2 from '../EditableItemsCard2'
import { putRequestToApi } from '../../api'
import HighlightedMessage from '../HighlightedMessage'

export default function EatingObjectiveCard({ setAddCard }) {

  const { 
    auth, 
    cookies,
    eating,
    setEating 
  } = React.useContext(PortalContext);


  const [error, setError] = React.useState(null)
  const [formData, setFormData] = React.useState({
    objective: ""
  })
  
  async function submitUpdatedData(bodyRequest) {
    await putRequestToApi(bodyRequest, '/food/goals', auth, cookies) 
  }

  const handleSubmit = (e) => {
    e.preventDefault()


    if (formData.objective !== "") {
      setError(null)

      const updatedGoals = eating.goals
      updatedGoals.push({
        content: formData.objective,
        manually_added: true,
        title: null
      })
      setEating(prev => ({...prev, goals: updatedGoals}))
      
      submitUpdatedData({
        id: +cookies.get("userId"), 
        name: formData.objective 
      })

      setAddCard(false)
    } else {
      setError("Por favor, preencha todos os campos")
    }
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  
  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
      <p className="body bottom-margin-xs">Objetivos pré-prontos</p>
      <div className="btn-grid">
        <Button type="secondary" link={true} to='/eating/objective/weigthloss'>Perda de peso</Button>
        <Button type="secondary" link={true} to='/eating/objective/cholesterolcontrol'>Controle do colesterol</Button>
        <Button type="secondary" link={true} to='/eating/objective/hypertensioncontrol'>Controle da hipertensão</Button>
        <Button type="secondary" link={true} to='/eating/objective/hearthealth'>Saúde do coração</Button>
        <Button type="secondary" link={true} to='/eating/objective/hypertrophy'>Ganho de massa muscular</Button>
      </div>
      {(error) ? <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null} 
      <form onSubmit={handleSubmit} className="top-margin-xs">
        <textarea name="objective" className="profile-question-form form-full-width shorter-form" 
          onChange={handleChange} type="text" placeholder="Adicione objetivo personalizado"
          value={formData.objective}/> 
        <Button type="primary top-margin-xs">Criar</Button>
        <button type="button" className="btn-tertiary left-margin-s" onClick={()=>{setAddCard()}}>Cancelar</button>
      </form>

    </div>
  )
}
