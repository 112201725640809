import React from 'react'
import chevronLeft from "../images/chevron-left.svg"
import chevronRight from "../images/chevron-right.svg"
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getDay, isToday, isAfter, addMonths } from "date-fns"

const WEEKDAYS = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]

const monthsEnToPt = {
  January: 'Janeiro',
  February: 'Fevereiro',
  March: 'Março',
  April: 'Abril',
  May: 'Maio',
  June: 'Junho',
  July: 'Julho',
  August: 'Agosto',
  September: 'Setembro',
  October: 'Outubro',
  November: 'Novembro',
  December: 'Dezembro'
}

export default function Calendar2({ medicationReminders, onDayClick, dayClicked, type="adherence" }) {

  const [relativeMonth, setRelativeMonth] = React.useState(0)

  const reminderDict = {}

  if (medicationReminders && type==="adherence") {
    medicationReminders.forEach(reminder => {
      if (reminderDict[format(reminder.date, "d/M/yyyy")]) {
        if (reminderDict[format(reminder.date, "d/M/yyyy")]  === "Pendente") {
          return
        } else {
           reminderDict[format(reminder.date, "d/M/yyyy")]= reminder.status 
        }
      } else {
        reminderDict[format(reminder.date, "d/M/yyyy")] = reminder.status
      }
    })
  }
  
  if (medicationReminders && type==="eating") {
    medicationReminders.forEach(reminder => {
      reminderDict[format(reminder.date, "d/M/yyyy")] = true
      //if (reminderDict[format(reminder.date, "d/M/yyyy")]) {
      //  if (reminderDict[format(reminder.date, "d/M/yyyy")]  === "Atenção") {
      //    return
      //  } else if (reminderDict[format(reminder.date, "d/M/yyyy")] === "Saudável") {
      //    return 
      //  } else {
      //     reminderDict[format(reminder.date, "d/M/yyyy")]= reminder.grade
      //  }
      //} else {
      //  reminderDict[format(reminder.date, "d/M/yyyy")] = reminder.grade
      //}
    })
  }
    
  if (medicationReminders && type==="exercise") {
    medicationReminders.forEach(reminder => {
      reminderDict[format(reminder.date, "d/M/yyyy")] = true
    })
  }

  const currentDate = addMonths(new Date(), relativeMonth);

  const firstDayOfMonth = startOfMonth(currentDate)
  const lastDayOfMonth = endOfMonth(currentDate)

  const daysInMonth = eachDayOfInterval({ 
    start: firstDayOfMonth, end: lastDayOfMonth
  })

  const startingDayIndex = getDay(firstDayOfMonth)
  const endingDayIndex = getDay(lastDayOfMonth)

  const monthYearPortuguese = (currentDate) => {
    const monthEn = format(currentDate, "MMMM")
    const monthPt = monthsEnToPt[monthEn]
    return `${monthPt} ${format(currentDate, "yyyy")}`
  }
  //<span className="month-name">{format(currentDate, "MMMM yyyy")}</span>

  return (
    <div className="datepicker">
      <div className="datepicker-top">
        <div className="month-selector">
          <button className="arrow" onClick={()=>setRelativeMonth(prev=>prev-1)}><i class="material-icons">
            <img src={chevronLeft}/>
          </i></button>
          <span className="month-name">{monthYearPortuguese(currentDate)}</span>
          <button className="arrow" onClick={()=>setRelativeMonth(prev=>prev+1)}>
            <img src={chevronRight}/>
          </button>
        </div>
      </div>
      <div className="datepicker-calendar">
        {WEEKDAYS.map((day) => {
          return (
            <span key={day} className="day">{day}</span>
          )
        })}
       
        {Array.from({length: startingDayIndex}).map((_, index) => {
          return <div key={`last-empty-${index}`}/>
        })}

        {daysInMonth.map((day, index) => {

          let dateLabel = ""
          if (type === "adherence") {
            dateLabel = (isToday(day)) ? "date current-day" : 
                (isAfter(day, new Date())) ? "date" :
                (reminderDict[format(day, "d/M/yyyy")]) ? 
                (reminderDict[format(day, "d/M/yyyy")]==="Confirmado" ? 
                "date day-confirmed" : "date day-pendent") : "date"
          } else if (type === "eating") {
            if (isToday(day)) {
              dateLabel = "date current-day"
            } else if (isAfter(day, new Date())) {
              dateLabel = "date"
            } else if (reminderDict[format(day, "d/M/yyyy")]==="Super saudável" || reminderDict[format(day, "d/M/yyyy")]===true) {
              dateLabel = "date day-confirmed"
            } else if (reminderDict[format(day, "d/M/yyyy")]==="Saudável" || reminderDict[format(day, "d/M/yyyy")]==="Em avaliação") {
              dateLabel = "date day-average"
            } else if (reminderDict[format(day, "d/M/yyyy")]==="Atenção") {
              dateLabel = "date day-pendent"
            } else {
              dateLabel = "date"
            }
          } else if (type="exercise") {
            if (isToday(day)) {
              dateLabel = "date current-day"
            } else if (isAfter(day, new Date())) {
              dateLabel = "date"
            } else if (reminderDict[format(day, "d/M/yyyy")]===true) {
              dateLabel = "date day-confirmed"
            } else {
              dateLabel = "date"
            }
          }

          if (format(day, "d/M/yyyy") === format(dayClicked, "d/M/yyyy")) {
            dateLabel = "date day-selected"
          }

          return (
            <button key={index} onClick={()=>{onDayClick(day)}} 
              className={dateLabel}
              >{format(day, "d")}
            </button>
          )
        })}

        {Array.from({length: 7 - (endingDayIndex + 1)}).map((_, index) => {
          return <div key={`next-empty-${index}`}/>
        })}
      </div>
    </div>
  )
}
