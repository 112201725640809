import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import EatingHistory from "../components/EatingHistory"
import Return from "../components/Forum/Return"

import EditableItemsCard2 from '../components/EditableItemsCard2'
import EditableItemForCard2 from '../components/EditableItemForCard2'
import { putRequestToApi, postRequestToApi, deleteDataFromApi } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import { format } from "date-fns"
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import Bulb from "../images/bulbDark.svg"
import Button from '../components/Button'
import RemoveIcon from '../images/remove.svg'
import ExerciseThumbnail from '../components/Exercise/ExerciseThumbnail'

export default function Exercise() {

  //const {id} = useParams()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { health, exercise, setExercise, exerciseWeek, setExerciseWeek, exerciseWeekLoading,
    exerciseHistory, setExerciseHistory,
    loadExerciseDataFromAPI, loadHealthDataFromAPI, auth, cookies } = React.useContext(PortalContext);

  const [addObjective, setAddObjective] = React.useState(false)

  const [dataModified, setDataModified] = React.useState(0)
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null
  })

  const navigate = useNavigate()
  const location = useLocation()

  // go to the top of the page
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/exercise', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadHealthDataFromAPI(+cookies.get("userId"))
      loadExerciseDataFromAPI()
    }
    
    if (exercise && exercise.goals !== null && exercise.goals.length === 0) {
      setAddObjective(true)
    }
  }, []);

  React.useEffect(() => {
    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        age: `${health[id].age}`,
      })

    }
  }, [health])
  
  React.useEffect(() => {
    if (exercise && exercise.goals !== null && exercise.goals.length === 0) {
      setAddObjective(true)
    }
  }, [exercise]);

  //console.log('exercise')
  //console.log(exercise)

  const addItemtoArray = (value) => {
    if (Object.keys(value).length !== 0) {
      setUserHealth(prev => {
        const newArray = prev.exerciseRecord
        newArray.push(value)
        return {...prev, exerciseRecord: newArray}
      })

      const body = {
        "id": +cookies.get("userId"),
        "name": 'exerciseRecord',
        "item": { description: value.description, date: format(value.date,"M/d/yyyy") }
      }

      postRequestToApi(body, 'userhealthlist', auth, cookies)
    }
  }

  const removeItemFromArray = (itemId) => {

    setUserHealth(prev => {
      const newArray = prev.exerciseRecord

      const index = newArray.findIndex(item => item.id===itemId)

      const body = {
        "id": +cookies.get("userId"),
        "name": 'exerciseRecord',
        "record_id": itemId
      }

      deleteDataFromApi(body, 'userhealthlist', auth, cookies)

      newArray.splice(index, 1)
      return {...prev, exerciseRecord: newArray}
    })
  }


  const editUserField = (name, value) => {
    if (value) {
      setUserHealth(prev => ({...prev, [name]: value}))
      
      setDataModified(prev => prev + 1)
    }
  }

  React.useEffect(() => {
    if (dataModified > 0) {
      console.log('user health changed, sending new data to API')

      const body = {
        "id": +cookies.get("userId"),
        "exercising_goals": userHealth.exercisingGoals
      }

      putRequestToApi(body, 'userexercising', auth, cookies) 
    }
  }, [dataModified])

  const deleteObjective = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'exercise/goals', auth, cookies)

    const updatedGoals = exercise.goals.filter(item => item.id !== id)  
    setExercise(prev => ({...prev, goals: updatedGoals}))
  }
  
  const removeExercise = (id) => {
    console.log(`removing exercise ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'exercise', auth, cookies)
    setExerciseWeek(prev => prev.filter(item => item.id !== id))
    setExerciseHistory(prev => prev.filter(item => item.id !== id))
  }

  const addObjectiveManually = (content) => {

    const requestBody = {
      "id": +cookies.get("userId"),
      "name": content.objective
    }

    putRequestToApi(requestBody, 'exercise/goals', auth, cookies)

    const updatedGoals = exercise.goals
    updatedGoals.push({
      //"id": eating.goals.length,
      "title": content.objective,
      "content": content.objective
    })
    setExercise(prev => ({...prev, goals: updatedGoals}))
  }

  const exercisesFiltered = exerciseWeek.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  exercisesFiltered.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date) - new Date(a.date);
  });
  //    <ProfileBriefing type="message" icon={Attention} header="Mensagem" 
  //        body="Você conseguiu fazer caminhadas todas as semanas nesse último mês, parabéns!
  //        Uma sugestão é ir alterando o itinerário para adicionar variedade no seu execício."
  //        />
  //
  return (
    (!userHealth.name || !exercise) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
    <>
    <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
      <Header tabletBreakpoint={false} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
    </div>

    <>
    <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

      <div className="top-margin-xl bottom-margin-xs">
        <Return to={`/profile`} backTo='Página inicial'/>
      </div>

      <p className="florence-title bottom-margin-l">Exercícios Físicos</p>

        <div className="bottom-margin-m">

          {(location.state?.message) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={location.state.message} />
            </div>
            : null }

          <div>
            <Button type="primary" link={true} to="/exercise/submit">
                  Adicionar novo exercício
            </Button>
          </div>

          <div className="content-two-columns top-margin-m">
            <Link className="thumbnail-link" to="/exercise">
              <p className="body"><strong>Acompanhamento</strong></p>
            </Link>
            <Link className="thumbnail-link" to="/exercise/history">
              <p className="body">Histórico</p>
            </Link>
          </div>
          <div className="progress-bar bottom-margin-m">
            <div className="rectangle full"></div>
            <div className="rectangle empty"></div>
          </div>

        </div>
        
        {(exercise?.message) ?
          <div className="bottom-margin-m">
            <HighlightedMessage icon={Bulb} type="message" header="Mensagem da Florence" body={exercise.message} />
          </div>
          : null 
        }

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Seus objetivos"
          icons={true}
          addCard={()=>setAddObjective(prev=>!prev)}
        >
          <div>
            {(addObjective) ? 
                <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddObjective(prev=>!prev)} 
                  addItemSubmit={(name, value) => addObjectiveManually(value)}
                  dictFields={["objective"]} userField={["objective"]} 
                  placeholder={["Adicione um objetivo"]}/>
              : null}
            {
              (exercise && exercise.goals.length > 0) ? exercise.goals.map((item, i) => (
                <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                  <div className='editable-item-for-card-header'>
                    <p key={i} className="body">{item.title}</p> 
                    {(item.id) ?
                    <div className="row-svg-icons left-margin-xs">
                      <button onClick={()=>{deleteObjective(item.id)}} className='carousel-btn'>
                        <img className="" src={RemoveIcon}/>
                      </button>
                    </div> : null }
                  </div>
                </div>
              )) : null
            }
          </div>
        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Seus exercícios essa semana"
          icons={false}
        >
          <div>

            {exercisesFiltered.map(exercise => <ExerciseThumbnail key={exercise.id} exercise={exercise} onRemove={() => removeExercise(exercise.id)}/>)}

            {(exercise.ids.length > exercisesFiltered.length && exerciseWeekLoading) ?
              <div className="icon-loading-middle-page top-margin-m">
                <p>Carregando seus exercícios...</p> 
              </div> : null }
          </div>
        </EditableItemsCard2>
    </div>
    </>
    </>
  )
}
