import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { differenceInDays, isAfter } from "date-fns"
import Header from "../components/Header"
import ExamCard from "../components/ExamCard"
import UploadDocumentCard from "../components/UploadDocumentCard"
import EditableItemsCard2 from "../components/EditableItemsCard2"
import EditableItemForCard2 from "../components/EditableItemForCard2"
import MedicationForm from "../components/Health/MedicationForm"
import CalendarHistory from "../components/CalendarHistory"
import PlotCard from "../components/PlotCard"
import Return from "../components/Forum/Return"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi, putRequestToApi, deleteDataFromApi, getDataFromApi } from '../api'
import UserBmiCard from '../components/UserBmiCard'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import PrescriptionCard from '../components/PrescriptionCard'
import MedicationCard from '../components/MedicationCard'
import PrescriptionForm from '../components/Health/PrescriptionForm'
import VaccineCard from '../components/VaccineCard'
import InsuranceForm from '../components/Health/InsuranceForm'
import EditableItemForCardEditSelection from '../components/EditableItemForCardEditSelection'
import EditableItemForCardCustomEdit from '../components/EditableItemForCardCustomEdit'
import EmergencyContactForm from '../components/Health/EmergencyContactForm'
import Button from '../components/Button'

export default function Health() {

  //const {id} = useParams()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { health, loadHealthDataFromAPI, setReload } = React.useContext(PortalContext);

  //cookies.set("userId", id, { path: '/' })
  
  const [showMoreData, setShowMoreData] = React.useState(false)

  const [showExams, setShowExams] = React.useState(false)
  const [showVaccines, setShowVaccines] = React.useState(false)
  const [showPrescriptions, setShowPrescriptions] = React.useState(false)
  const [showMedications, setShowMedications] = React.useState(false)
  const [showBodyMeasures, setShowBodyMeasures] = React.useState(false)
  const [showHealthHistory, setShowHealthHistory] = React.useState(false)
  const [showFamilyHealthHistory, setShowFamilyHealthHistory] = React.useState(false)

  const [editEmergencyContact, setEditEmergencyContact] = React.useState(false)
  
  const [addMedication, setAddMedication] = React.useState(false)
  const [addDisease, setAddDisease] = React.useState(false)
  const [addAllergy, setAddAllergy] = React.useState(false)
  const [addHealthHistory, setAddHealthHistory] = React.useState(false)
  const [addFamilyHealthHistory, setAddFamilyHealthHistory] = React.useState(false)
  const [addExam, setAddExam] = React.useState(false)
  const [addInsurance, setAddInsurance] = React.useState(false)

  const [examSubmitted, setExamSubmitted] = React.useState(false)

  const [addPrescription, setAddPrescription] = React.useState(false)
  const [prescriptionSubmitted, setPrescriptionSubmitted] = React.useState(false)
  
  const [addVaccine, setAddVaccine] = React.useState(false)
  const [vaccineSubmitted, setVaccineSubmitted] = React.useState(false)
  
  const [dataModified, setDataModified] = React.useState(0)

  const [historyItemsData, setHistoryItemsData] = React.useState(null)

  const [deleteExamId, setDeleteExamId] = React.useState(null)
  const [deletePrescriptionId, setDeletePrescriptionId] = React.useState(null)
  const [deleteVaccineId, setDeleteVaccineId] = React.useState(null)

  const [deleting, setDeleting] = React.useState(false)

  const [sharedStatus, setSharedStatus] = React.useState("idle")
  const [sharedMessage, setSharedMessage] = React.useState(null)

  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null,
    smoking: "Não informado"
  })

  const [errorHeight, setErrorHeight] = React.useState(null)
  const [errorWeight, setErrorWeight] = React.useState(null)

  const navigate = useNavigate()

  // go to the top of the page
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/health', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadHealthDataFromAPI(+cookies.get("userId"))
    }
  }, []);

  React.useEffect(() => {
    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        age: `${health[id].age}`,
        smoking: health[id].smoking_status ? `${health[id].smoking_status}` : '',
        organ_donation: health[id].organ_donation ? health[id].organ_donation : '',
        blood_type: health[id].organ_donation ? health[id].blood_type : '',
        sus_number: health[id].sus_number ? health[id].sus_number : '',
        emergency_contact: (health[id].emergency_contact.name)
          ? health[id].emergency_contact : {name: "", phone_number: null},
        insurance: health[id].insurance ? health[id].insurance : [],
        medication: (health[id].medication || health[id].medication.length===0 ) ? health[id].medication : [],
        allergy: (health[id].allergy || health[id].allergy.length===0 ) ? health[id].allergy : [],
        exam: (health[id].exams || health[id].disease.exams===0 ) ? 
          health[id].exams.map(exam => ({...exam, show: false}))  
          : [],
        prescription: (health[id].prescriptions) ? 
          health[id].prescriptions : {},
        vaccine: (health[id].vaccines) ? 
          health[id].vaccines : [],
        disease: (health[id].disease || health[id].disease.length===0 ) ? health[id].disease : [],
        nextAppointment: health[id].next_appointment ? `${health[id].next_appointment}` : '',
        height: health[id].height ? `${health[id].height}` : '',
        weight: health[id].weight ? `${health[id].weight}` : '',
        bmi: health[id].bmi ? `${health[id].bmi}` : null,
        healthHistory: (health[id].health_history || health[id].health_history.length===0 ) ? health[id].health_history : [],
        familyHealthHistory: (health[id].family_health_history || health[id].family_health_history.length===0 ) ? 
          health[id].family_health_history : [],
        medicationAdherence: (health[id].medication_adherence) ? 
          health[id].medication_adherence.map(item => ({
            id: item.id, date: new Date(item.date), frequency: item.frequency, medication: item.medication, status: item.status
        })) : []
      })
      if (health[id].medication !== null && health[id].medication.length === 0) {
        setAddPrescription(true)
        setAddMedication(true)
      }

    }
  }, [health])

  React.useEffect(() => {
    if (dataModified > 0) {
      //console.log('user health changed, sending new data to API')

      const body = {
        "id": +cookies.get("userId"),
        "smoking_status": userHealth.smoking,
        "height": userHealth.height,
        "weight": userHealth.weight,
        "next_appointment": userHealth.nextAppointment,
        "sus_number": userHealth.sus_number,
        "blood_type": userHealth.blood_type,
        "organ_donation": userHealth.organ_donation,
        "emergency_contact": userHealth.emergency_contact
      }

      putRequestToApi(body, 'userhealth', auth, cookies) 
    }
  }, [dataModified])


  React.useEffect(() => {

    let confirmed = 0
    let pendent = 0

    if (userHealth.medicationAdherence) {
      userHealth.medicationAdherence.forEach((item) => {
        if (differenceInDays(new Date(), item.date) <= 6) {
          if (item.status === "Confirmado") {
            confirmed += 1
          } else if (item.status === "Pendente") {
            if (!isAfter(item.date, new Date())) {
              pendent += 1
            }
          }
        }
      })
    }
    
    setHistoryItemsData([
      {"class": "Confirmado", "colour": "green", "value": confirmed},
      {"class": "Pendente", "colour": "red", "value": pendent}
    ])

    // for the cards that are empty, show should be true
    if (userHealth && userHealth.exam) { 
      if (userHealth.exam.length === 0) {
        setShowExams(true)
      }
      if (userHealth.familyHealthHistory.length === 0) {
        setShowFamilyHealthHistory(true)
      }
      if (userHealth.healthHistory.length === 0) {
        setShowHealthHistory(true)
      }
      if (userHealth.vaccine.length === 0) {
        setShowVaccines(true)
      }
      if (userHealth.medication.length === 0) {
        setShowMedications(true)
      }
      if ((Object.keys(userHealth.prescription).length <= 1) && (userHealth.prescription.no_prescription.length === 0)) {
        setShowPrescriptions(true)
      }
    }


  }, [userHealth])


  async function shareData() {
    setSharedStatus('submitting')
    let response = null
    try {
      response = await getDataFromApi(`generate_code/${cookies.get("userId")}`, auth, cookies)

    } catch(err) {
      console.log('error generating shared code')
      console.log(err)
      setSharedStatus('idle')
      setSharedMessage('Ocorreu um erro na geração do código. Por favor, tente mais tarde')
    } finally {
      setSharedStatus('idle')
      setSharedMessage(`Geramos um código para você poder compartilhar seus dados de saúde. Esse código é válido por apenas 24 horas:\n\n- Nome: ${health[+cookies.get("userId")].first_name}\n- Código: ${response.share_code}\n\nComo acessar os dados compartilhados: Na página inicial da Florence, clique em 'Acessar dados compartilhados' no menu superior, e adicione as credenciais acima.`)
    }
  }

  const editUserField = (name, value) => {

    // if value is empty, it does not enter the if statement
    if (value) {
      if (name === "height") {
        if (+value) {
          setErrorHeight(null)

          if (+userHealth.weight) {
            const bmi = userHealth.weight / (value * value)
            setUserHealth(prev => ({...prev, bmi: `${parseFloat(bmi).toFixed(1)}`}))
          }

        } else {
          setErrorHeight("Insira um número válido para a altura. Utilize ponto ao invés de vírgula. Por exemplo: 1.80")
          return
        }
      } else if (name === "weight") {
        if (+value) {
          setErrorWeight(null)

          if (+userHealth.height) {
            const bmi = value / (userHealth.height * userHealth.height)
            setUserHealth(prev => ({...prev, bmi: `${parseFloat(bmi).toFixed(1)}`}))
          }

        } else {
          setErrorWeight("Insira um número válido para o peso. Utilize ponto ao invés de vírgula. Por exemplo: 70.5")
          return
        }
      }

      setUserHealth(prev => ({...prev, [name]: value}))
      
      setDataModified(prev => prev + 1)
    }

  }


  const setEmergencyContact = (emergencyContact) => { 
      setUserHealth(prev => ({...prev, emergency_contact: emergencyContact}))
      
      setDataModified(prev => prev + 1)
  }

  const editUserItemField = (name, item, value) => {
    setUserHealth(prev => {
      const newArray = prev[name]
      newArray[item] = value
      return {...prev, [name]: newArray}
    })

    setDataModified(prev => prev + 1)
  }

  const addItemtoArray = (name, value) => {

    // before making the request, check if object is not empty
    if (Object.keys(value).length !== 0) {
        setUserHealth(prev => {
        const newArray = prev[name]
        newArray.push(value)
        return {...prev, [name]: newArray}
      })

      //setDataAdded(prev => prev + 1)

      const body = {
        "id": +cookies.get("userId"),
        "name": name,
        "item":value
      }
      postRequestToApi(body, 'userhealthlist', auth, cookies)
    }
    //window.location.reload();
    if (name === "medication") {
      // add to the state
      value.id = userHealth.prescription.no_prescription.length + 1
      setUserHealth(prev => ({
          ...prev, 
          prescription: {...prev.prescription, no_prescription: [...prev.prescription.no_prescription, value]}
        }
      ))
    }
  }

  const removeItemFromArray = (name, index) => {
    setUserHealth(prev => {
      const newArray = prev[name]

      const body = {
        "id": +cookies.get("userId"),
        "name": name,
        "record_id": newArray.at(index).id
      }
      deleteDataFromApi(body, 'userhealthlist', auth, cookies)

      newArray.splice(index, 1)
      return {...prev, [name]: newArray}
    })

  }

  const onMedicationConfirmation = (itemId) => {
    setUserHealth(prev => {
      const newArray = []
     
      prev.medicationAdherence.forEach((item) => {
        if (item.id === itemId) {
          newArray.push({...item, status: "Confirmado"})
        } else {
          newArray.push(item)
        }
      })

      const body = {
        "id": +cookies.get("userId"),
        "item_id": itemId
      }
      postRequestToApi(body, 'adherence', auth, cookies)

      return {...prev, medicationAdherence: newArray}
    })
  }

  const ToggleExamShow = (id) => {
    // create a show tag for the exams
    if (userHealth.exam) {
      setUserHealth(prev => {
        const newArray = userHealth.exam

        newArray[id].show = !userHealth.exam[id].show
        return {...prev, exam: newArray}
      })
    }
  }

  async function deleteExam(body) {
    try {
      setDeleting(true)
      //console.log(body)
      //const data = await getDataFromApi('myprofile', auth, cookies)
      const data = await deleteDataFromApi(body, 'exam', auth, cookies)

      // remove exam from dictionary state, so the page is updated live
      //userHealth.exam.filter(exam => exam.id !== body.id)
      setUserHealth(prev => ({...prev, exam: prev.exam.filter(item => item.id !== body.id) }))

      setDeleteExamId(null)

      //console.log("exam deleted")
      //window.location.reload();
      setReload(prev => !prev)

      setAuth(prev => ({...prev, ...data.tokens}))
    } catch (err) {
      console.log("Could not delete exam")
      console.log(err)
      setDeleting(false)
    } finally {
      setDeleting(false)
    }
  }

  async function deletePrescription(body) {
    try {
      setDeleting(true)
      //console.log(body)
      //const data = await getDataFromApi('myprofile', auth, cookies)
      const data = await deleteDataFromApi(body, 'prescription', auth, cookies)

      // remove exam from dictionary state, so the page is updated live
      //userHealth.exam.filter(exam => exam.id !== body.id)
      const prevPrescriptions = userHealth.prescription
      delete prevPrescriptions[String(body.id)]
      setUserHealth(prev => ({...prev, prescription: prevPrescriptions}))
      setDeletePrescriptionId(null)

      //console.log("exam deleted")
      //window.location.reload();
      setReload(prev => !prev)

      setAuth(prev => ({...prev, ...data.tokens}))
    } catch (err) {
      console.log("Could not delete exam")
      console.log(err)
      setDeleting(false)
    } finally {
      setDeleting(false)
    }
  }

  async function deleteVaccine(body) {
    try {
      setDeleting(true)
      //console.log(body)
      //const data = await getDataFromApi('myprofile', auth, cookies)
      const data = await deleteDataFromApi(body, 'vaccine', auth, cookies)

      // remove exam from dictionary state, so the page is updated live
      //userHealth.exam.filter(exam => exam.id !== body.id)
      const prevVaccine = userHealth.vaccine
      delete prevVaccine.splice(deleteVaccineId, 1)
      setUserHealth(prev => ({...prev, vaccine: prevVaccine}))

      setDeleteVaccineId(null)

      //console.log("exam deleted")
      //window.location.reload();
      setReload(prev => !prev)

      setAuth(prev => ({...prev, ...data.tokens}))
    } catch (err) {
      console.log("Could not delete exam")
      console.log(err)
      setDeleting(false)
    } finally {
      setDeleting(false)
    }
  }

  const onExamConfirmDelete = (id) => {
    //console.log("confirm exam delete")
    //console.log(id)
    deleteExam({"id": id})
  }
  
  const onPrescriptionConfirmDelete = (id) => {
    //console.log("confirm exam delete")
    //console.log(id)
    deletePrescription({"id": id})
  }


  const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }

  const userBmiCard = <UserBmiCard bmi={userHealth.bmi}/>

  async function deleteMedication(body) {
    try {
      //console.log(body)
      //const data = await getDataFromApi('myprofile', auth, cookies)
      const data = await deleteDataFromApi(body, 'medication', auth, cookies)

      setReload(prev => !prev)

      setAuth(prev => ({...prev, ...data.tokens}))
    } catch (err) {
      console.log("Could not delete exam")
      console.log(err)
    }
  }

  const onVaccineConfirmDelete = (id) => {
    deleteVaccine({"id": id})
  }

  const removeMedication = (prescriptionKey, medicationId, medicationIndex) => {
    if (prescriptionKey) {
      const prescriptionsUpdated = userHealth.prescription
      prescriptionsUpdated[prescriptionKey].medication.splice(medicationIndex, 1)
      setUserHealth(prev => ({...prev, prescription: prescriptionsUpdated}))
    } else { 
      const prescriptionsUpdated = userHealth.prescription
      prescriptionsUpdated.no_prescription.splice(medicationIndex, 1)
      setUserHealth(prev => ({...prev, prescription: prescriptionsUpdated}))
    }
    deleteMedication({id: medicationId})
  }

  let vaccines = []
  if (userHealth.vaccine) {
    vaccines = userHealth.vaccine.map((item, index) => {
      return (
        <VaccineCard
          vaccine={item} 
          listId={index}
          deleteId={deleteVaccineId}
          setDeleteId={setDeleteVaccineId}
          onConfirmDelete={onVaccineConfirmDelete}
          deleting={deleting}
        />
      )
    })
  }

  let prescriptionToVerify = false
  let prescriptions = []
  let medications = []
  if (userHealth.prescription) {
    Object.keys(userHealth.prescription).forEach((key, index) => {
      
      if (key !== 'no_prescription') {
        if (userHealth.prescription[key].confirmed === false && userHealth.prescription[key].data_extracted === true) {
          prescriptionToVerify = true
        }
        prescriptions.push(
          <PrescriptionCard 
            prescription={userHealth.prescription[key]}
            listId={index}
            id={key}
            deleteId={deletePrescriptionId}
            setDeleteId={setDeletePrescriptionId}
            onConfirmDelete={onPrescriptionConfirmDelete}
            deleting={deleting}
          />
        )
        
        if (userHealth.prescription[key].confirmed && userHealth.prescription[key].medication.length > 0) {
          userHealth.prescription[key].medication.forEach((element, index) => {
              medications.push(
                <MedicationCard
                  medication={element}
                  medicationIndex={index}
                  prescriptionId={key}
                  prescriptionExternalFile={userHealth.prescription[key].external_filename}
                  removeMedication={removeMedication}
                />
              )
            }
          )
        }
      } else {
        userHealth.prescription[key].forEach((element, index) => {
          medications.push(
            <MedicationCard
              medication={element}
              medicationIndex={index}
              prescriptionId={null}
              removeMedication={removeMedication}
            />
          )
        })
      }
    })
  }


  if (health[+cookies.get("userId")] === null) {
    return <p>Carregando página</p>
  }

   //   <EditableItemsCard2 
   //     clsName="bottom-margin-l"
   //     title="Próxima ida ao médico"
   //     icons={false}
   //   >
   //     <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["nextAppointment"]}
   //       placeholder={["Dia e horário da sua próxima visita médica"]}>
   //       <p className="body">{userHealth.nextAppointment}</p>
   //     </EditableItemForCard2>
   //   </EditableItemsCard2>
  

  return (
    (!userHealth.name) ? 
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>Carregando página...</p> 
    </div>
    :  
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
      </div>
      <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

        <div className="top-margin-xl bottom-margin-xs">
          <Return to={`/profile`} backTo="Página inicial"/>
        </div>

        <p className="florence-title bottom-margin-l">Portal de Saúde</p>

        <div className="bottom-margin-m">

          {(sharedMessage) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={sharedMessage} />
            </div>
            : null }

          <div>
            {(sharedStatus!=='idle') ?
              <Button type="primary" onClick={()=>shareData()}>Gerando código...</Button> :
              <Button type="primary" onClick={()=>shareData()}>Compartilhar dados de saúde</Button>
            }
          </div>
        </div>

        <div className="content-two-columns">
          <Link className="thumbnail-link" to="/health">
            <p className="body"><strong>Dados de saúde</strong></p>
          </Link>
          <Link className="thumbnail-link" to="/health/adherence">
            <p className="body">Uso de medicação</p>
          </Link>
        </div>
        <div className="progress-bar bottom-margin-m">
          <div className="rectangle full"></div>
          <div className="rectangle empty"></div>
        </div>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Dados gerais"
          icons={false}
        >
          <EditableItemForCard2>
            <p className="body"><strong>Nome: </strong>{userHealth.name}</p>
          </EditableItemForCard2>
          <EditableItemForCard2>
            <p className="body"><strong>Idade: </strong>{userHealth.age} anos</p>
          </EditableItemForCard2>
          
          <EditableItemForCardEditSelection 
                edit={true} 
                userField={["smoking"]}
                editUserField={editUserField} 
                responseOptions={["Sim", "Não"]}
                gridClass="content-two-columns">
            <p className="body"><strong>Fumante: </strong>{userHealth.smoking}</p>
          </EditableItemForCardEditSelection>
        </EditableItemsCard2>
        
        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Prescrições Médicas"
          icons={true}
          userAdd={true}
          toggleCard={()=>setShowPrescriptions(prev=>!prev)}
          addCard={()=> {
            setAddPrescription(prev=>!prev)
            setPrescriptionSubmitted(false)
          }}
          showCard={showPrescriptions}
        >
          <div>
            {(addPrescription) ?
              <UploadDocumentCard 
                setAddCard={()=>setAddPrescription(prev=>!prev)} 
                examSubmitted={prescriptionSubmitted} 
                setExamSubmitted={setPrescriptionSubmitted}
                document="prescription"
              />
              : null}
            {
              (userHealth.prescription) ?
                    prescriptions
                    : null
            }
          </div>
        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Medicamentos"
          icons={true}
          toggleCard={()=>setShowMedications(prev=>!prev)}
          showCard={showMedications}
          addCard={()=>setAddMedication(prev=>!prev)}
        >
          <div>
            {(addMedication) ? 
              <MedicationForm 
                setAddMedication={()=>setAddMedication(prev=>!prev)}
                submitNewItem={(name, value) => addItemtoArray(name, value)}
              />
              : null}

            {
              (prescriptionToVerify) ?
              <div className="container-simple">
                <HighlightedMessage 
                        icon={Attention} 
                        type="warning" 
                        header="Atenção" 
                        body="Você tem prescrições pendentes para conferência. Após confirmá-las, os medicamentos aparecerão aqui." />
              </div> : null
            }

            {
              (userHealth.medication) ? medications : null 
            }

          </div>
        </EditableItemsCard2>

        <Button type="tertiary bottom-margin-l" onClick={()=>setShowMoreData(prev=>!prev)}>
          {showMoreData ? "Mostrar menos dados de saúde" : "Mostrar mais dados de saúde" }
        </Button>

        {showMoreData ? (
        <div>

        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Emergência"
          icons={false}
        >
          <EditableItemForCardEditSelection 
                edit={true} 
                userField={["blood_type"]}
                editUserField={editUserField} 
                responseOptions={["AB+", "AB-", "A+", "A-", "B+", "B-", "O+", "O-"]}
                gridClass="content-four-columns">
            <p className="body"><strong>Tipo sanguíneo: </strong>{userHealth.blood_type}</p>
          </EditableItemForCardEditSelection>

          <EditableItemForCardEditSelection 
                edit={true} 
                userField={["organ_donation"]}
                editUserField={editUserField} 
                responseOptions={["Sou doador", "Não sou doador"]}
                gridClass="content-two-columns">
            <p className="body"><strong>Doador de orgãos: </strong>{userHealth.organ_donation}</p>
          </EditableItemForCardEditSelection>

          <EditableItemForCardCustomEdit
              edit={true}
              userField={["emergency_contact"]}
              setEditForm={setEditEmergencyContact}
              editing={editEmergencyContact}
              editionForm={
                <EmergencyContactForm 
                    setEmergencyContact={setEmergencyContact} 
                    setEditEmergencyContact={setEditEmergencyContact}
                  />
              }>
            <div>
              <p className="body"><strong>Contato de emergência: </strong>{userHealth.emergency_contact.name}</p>
              {(userHealth.emergency_contact.phone_number !== null) ?
              <p className="secondary-text">Telefone: {userHealth.emergency_contact.phone_number}</p> : null
              }
            </div>
          </EditableItemForCardCustomEdit>
        </EditableItemsCard2>


        <EditableItemsCard2 
          clsName="bottom-margin-l"
          title="Planos de saúde"
          icons={true}
          addCard={()=>setAddInsurance(prev=>!prev)}
        >
          {
            (addInsurance) ?
            <InsuranceForm
              setAddInsurance={()=>setAddInsurance(prev=>!prev)}
              submitNewItem={(name, value) => addItemtoArray(name, value)}
            />
            : null
          }
          <EditableItemForCard2 edit={true} 
            userField={["sus_number"]} editUserField={editUserField} placeholder={["Número do cartão do SUS"]}>
            <p className="body"><strong>Cartão SUS: </strong>{userHealth.sus_number}</p>
          </EditableItemForCard2>
              
          {(userHealth.insurance) ? 
            userHealth.insurance.map((item, i) => ([
              <EditableItemForCard2 key={i} edit={false} remove={item.id ? true : false}
                dictFields={["number"]} userField={["insurance"]} listId={i} 
                editUserField={editUserItemField} deleteItem={removeItemFromArray}>
                <div key={i}>
                  <p className="body">{item.name}</p> 
                  <p className="secondary-text">{
                    `Número de registro: ${item.number}`
                  }
                  </p>
                </div>
              </EditableItemForCard2>
            ])) : null 
          }

        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Alergias"
          icons={true}
          addCard={()=>setAddAllergy(prev=>!prev)}
        >
          <div>
            {(addAllergy) ? 
                <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddAllergy(prev=>!prev)} 
                  addItemSubmit={(name, value) => addItemtoArray(name, value)}
                  dictFields={["allergy"]} userField={["allergy"]} 
                  placeholder={["Nome da alergia"]}/>
              : null}
            {
            (userHealth.allergy) ? userHealth.allergy.map((allergy, i) => ([
                <EditableItemForCard2 key={i} edit={false} remove={allergy.id ? true : false}
                  userField={["allergy"]} listId={i} 
                  editUserField={editUserItemField} placeholder={["Nome do alergia"]}
                  deleteItem={removeItemFromArray}>
                  <div key={i}>
                    <p className="body">{allergy.allergy}</p> 
                  </div>
                </EditableItemForCard2>
              ])) : null 
            }
          </div>
        </EditableItemsCard2>
        
        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Doenças"
          icons={true}
          addCard={()=>setAddDisease(prev=>!prev)}
        >
          <div>
            {(addDisease) ? 
                <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddDisease(prev=>!prev)} 
                  addItemSubmit={(name, value) => addItemtoArray(name, value)}
                  dictFields={["disease"]} userField={["disease"]} 
                  placeholder={["Nome da doença"]}/>
              : null}
            {
            (userHealth.disease) ? userHealth.disease.map((disease, i) => ([
                <EditableItemForCard2 key={i} edit={false} remove={disease.id ? true : false}
                  userField={["disease"]} listId={i} 
                  editUserField={editUserItemField} placeholder={["Nome do doença"]}
                  deleteItem={removeItemFromArray}>
                  <div key={i}>
                    <p className="body">{disease.disease}</p> 
                  </div>
                </EditableItemForCard2>
              ])) : null 
            }
          </div>
        </EditableItemsCard2>



          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Medidas corporais"
            icons={true}
            userAdd={false}
            toggleCard={()=>setShowBodyMeasures(prev=>!prev)}
            showCard={showBodyMeasures}
          >
            { (errorHeight) ?
              <div className="container-simple">
                <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorHeight} />
              </div> : null
            }
            { (errorWeight) ?
              <div className="container-simple">
                <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorWeight} />
              </div> : null
            }
            <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["height"]}
              placeholder={["Sua altura em metros"]}>
              <p className="body"><strong>Altura (m): </strong>{userHealth.height}</p>
            </EditableItemForCard2>
            <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["weight"]}
              placeholder={["Seu peso em kg"]}>
              <p className="body"><strong>Peso (kg): </strong>{userHealth.weight}</p>
            </EditableItemForCard2>
            {userBmiCard}
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Exames Médicos"
            icons={true}
            userAdd={true}
            toggleCard={()=>setShowExams(prev=>!prev)}
            addCard={()=> {
              setAddExam(prev=>!prev)
              setExamSubmitted(false)
            }}
            showCard={showExams}
          >
            <div>
              {(addExam) ?
                <UploadDocumentCard 
                  setAddCard={()=>setAddExam(prev=>!prev)} 
                  examSubmitted={examSubmitted} 
                  setExamSubmitted={setExamSubmitted}
                />
                : null}
              {
                (userHealth.exam) ?
                userHealth.exam.map((exam, i) => (
                  <ExamCard 
                      key={i} 
                      exam={exam} 
                      toggleShowExam={ToggleExamShow} 
                      listId={i} 
                      id={exam.id} 
                      patientId={+cookies.get("userId")}
                      deleteId={deleteExamId}
                      setDeleteId={setDeleteExamId}
                      onConfirmDelete={onExamConfirmDelete}
                      deleting={deleting}
                    />
                )) : null
              }
            </div>
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Vacinação"
            icons={true}
            userAdd={true}
            toggleCard={()=>setShowVaccines(prev=>!prev)}
            addCard={()=> {
              setAddVaccine(prev=>!prev)
              setVaccineSubmitted(false)
            }}
            showCard={showVaccines}
          >
            <div>
              {(addVaccine) ?
                <UploadDocumentCard 
                  setAddCard={()=>setAddVaccine(prev=>!prev)} 
                  examSubmitted={vaccineSubmitted} 
                  setExamSubmitted={setVaccineSubmitted}
                  document="vaccine"
                />
                : null}
              { vaccines }
            </div>
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Histórico de Saúde"
            icons={true}
            userAdd={true}
            addCard={()=>setAddHealthHistory(prev=>!prev)}
            toggleCard={() => setShowHealthHistory(prev=>!prev)}
            showCard={showHealthHistory}
          >
            <div>
              {(addHealthHistory) ? 
                  <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddHealthHistory(prev=>!prev)} 
                    addItemSubmit={(name, value) => addItemtoArray(name, value)}
                    dictFields={["description"]} userField={["healthHistory"]} 
                    placeholder={["Descrição"]} dateField="created"/>
                : null}
              {
              (userHealth.healthHistory) ? userHealth.healthHistory.map((item, i) => ([
                  <EditableItemForCard2 key={i} edit={false} remove={item.id ? true : false}
                    dictFields={["description"]} userField={["healthHistory"]} listId={i} 
                    editUserField={editUserItemField} placeholder={["Descrição"]}
                    deleteItem={removeItemFromArray} dateField="created">
                    <div key={i}>
                      <p className="body">{item.description}</p> 
                      <p className="secondary-text">{
                        `Adicionado em ${new Date(item.created).toLocaleDateString('pt-BR', dateOptions)}`
                      }
                      </p>
                    </div>
                  </EditableItemForCard2>
                ])) : null 
              }
            </div>
          </EditableItemsCard2>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Histórico Familiar"
            icons={true}
            userAdd={true}
            addCard={()=>setAddFamilyHealthHistory(prev=>!prev)}
            toggleCard={() => setShowFamilyHealthHistory(prev=>!prev)}
            showCard={showFamilyHealthHistory}
          >
            <div>
              {(addFamilyHealthHistory) ? 
                  <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddFamilyHealthHistory(prev=>!prev)} 
                    addItemSubmit={(name, value) => addItemtoArray(name, value)}
                    dictFields={["description"]} userField={["familyHealthHistory"]} 
                    placeholder={["Descrição"]} dateField="created"/>
                : null}
              {
              (userHealth.familyHealthHistory) ? userHealth.familyHealthHistory.map((item, i) => ([
                  <EditableItemForCard2 key={i} edit={false} remove={item.id ? true : false}
                    dictFields={["description"]} userField={["familyHealthHistory"]} listId={i} 
                    editUserField={editUserItemField} placeholder={["Descrição"]}
                    deleteItem={removeItemFromArray} dateField="created">
                    <div key={i}>
                      <p className="body">{item.description}</p> 
                      <p className="secondary-text">{
                        `Adicionado em ${new Date(item.created).toLocaleDateString('pt-BR', dateOptions)}`
                      }
                      </p>
                    </div>
                  </EditableItemForCard2>
                ])) : null 
              }
            </div>
          </EditableItemsCard2>
          </div> ) : null }

      </div>
      </>
    </>
  )
}
