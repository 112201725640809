import React from 'react'
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Return from '../Forum/Return'
import EnergyExpenditureCard from './EnergyExpenditureCard'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import EditableItemsCard2 from '../EditableItemsCard2'
import Button from '../Button'
import { postRequestToApi } from '../../api'

export default function HypertrophyObjective() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { 
    health,
    loadHealthDataFromAPI 
  } = React.useContext(PortalContext);

  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [status, setStatus] = React.useState('idle')
  
  const navigate = useNavigate()
  
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadHealthDataFromAPI(+cookies.get("userId"))
      //loadWeightDataFromAPI()
    }
  }, []);
  
  async function submitUpdatedData(bodyRequest) {
    try {
      setStatus("submitting")
      const data = await postRequestToApi(bodyRequest, '/food/goals', auth, cookies)

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      setStatus("idle")
      navigate('/eating')
    }
  }

  const addObjectives = () => {
    const requestBody = {
      'goalsArray': goals
    }

    submitUpdatedData({
      id: +cookies.get("userId"), 
      exam_data: requestBody
    })

    //navigate('/eating')
  }

  if (!health[+cookies.get("userId")]?.first_name) {
      return (
        <div className="icon-loading-middle-page">
          <img className="small-logo-nav loading-icon" 
              src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando página...</p> 
        </div>
      )
  }

  let tdeeLow = null
  let tdeeHigh = null

  let proteinsLow = null
  let proteinsHigh = null

  let goals = null

  let objectiveProposal = null
  if (health[+cookies.get("userId")]?.tdee || health[+cookies.get("userId")].tdee !== null) {
    const id = +cookies.get("userId")

    tdeeLow = Math.round(+health[id].tdee + 200)
    tdeeHigh = Math.round(+health[id].tdee + 400)

    proteinsLow = Math.round(+health[id].weight * 1.6)
    proteinsHigh = Math.round(+health[id].weight * 2.2)
    
    goals = [
      {
        "title": "Consumo diário de calorias",
        "content": `Entre ${tdeeLow} e ${tdeeHigh} calorias`,
        "manually_added": false,
      },
      {
        "title": "Consumo diário de proteínas",
        "content": `Entre ${proteinsLow} e ${proteinsHigh} gramas`,
        "manually_added": false,
      },
      {
        "title": "Carboidratos",
        "content": "40% a 65% da dieta, provenientes de alimentos integrais, como arroz, aveia, batata e batata doce, raízes (como cenoura), feijão e legumes, massas e pão fresco",
        "manually_added": false,
      }, 
      {
        "title": "Gorduras",
        "content": "Monoinsaturadas e poli-insaturadas, provenientes de derivados do leite, peixes, aves, algumas frutas e vegetais, como nozes, castanhas e abacate",
        "manually_added": false,
      }, 
      {
        "title": "Meta",
        "content": "Aumento do peso corporal de 1% a 2% ao mês",
        "manually_added": false,
      }

    ]

    objectiveProposal = (
      <div>
        <p className="body top-margin-s">
          <strong>Um bom início é um 
          balanço positivo de 200-400 calorias: </strong>
          entre {tdeeLow} e {tdeeHigh} calorias por dia
        </p>
        <p className="secondary-text">
          Pessoas naturalmente magras ou com dificuldade em ganhar massa muscular devem tender para o limite superior desse intervalo
        </p>

        <p className="body top-margin-s">
          <strong>Sua ingestão de proteínas </strong>
          também dita sua habilidade de criar músculos. 
          Um bom início é consumir entre {proteinsLow} g e {proteinsHigh} g diariamente
        </p>
        <p className="secondary-text">
          Entre 1.6 g e 2.2 g por kg de peso corporal por dia
        </p>
        
        <p className="body top-margin-s">
          <strong>A quantidade de carboidratos é bastante individual, </strong>
          entre 40% e 65% da dieta, e são cruciais para performance: eles fornecem até 
          80% da energia para os músculos
        </p>
        <p className="secondary-text">
          A maioria dos carboidratos devem vir de comidas integrais: arroz, aveia, batata e batata doce, raízes (como cenoura), feijão e legumes, massas e pão fresco
        </p>
        
        <p className="body top-margin-s">
          <strong>O restante das calorias devem ser provenientes de gorduras </strong>
          (monoinsaturadas e poli-insaturadas, em particular omega-3)
        </p>
        <p className="secondary-text">
          As gorduras devem vir de comidas de verdade: derivados do leite, peixes, aves, algumas frutas e vegetais, como nozes, castanhas e abacate
        </p>
        
        <p className="body top-margin-s">
          <strong>Avaliando o progresso: </strong>
          1% a 2% de aumento do peso corporal por mês
        </p>
        <p className="secondary-text bottom-margin-s">
          O ganho de massa muscular leva tempo, e é necessário monitorar o progresso em meses, e não em dias ou semanas
        </p>


        <EditableItemsCard2 
          clsName="bottom-margin-s" 
          title="Objetivos para hipertrofia"
          icons={false}
        >
          {goals.map((item,i) => (
            <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p>
              </div>
            </div>
          ))}
        </EditableItemsCard2>

        <Button type="primary bottom-margin-xl" onClick={()=>{addObjectives()}}>
          {(status==="idle") ? "Adicionar objetivos" : "Adicionando..." }
        </Button>
      </div> 
    )
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to="/eating" />
      <p className="heading3 top-margin-m"><strong>Ganho de massa muscular</strong></p>
      <p className="body top-margin-s bottom-margin-s">
        Calorias são o principal fator para o ganho de massa muscular ou hipertrofia: 
        é necessário estar com balanço de energia positivo - <strong>excesso calórico</strong> - 
        para a construção de músculos.

      </p>

      <EnergyExpenditureCard />

      {(health[+cookies.get("userId")]?.tdee || health[+cookies.get("userId")].tdee !== null) ? 
        objectiveProposal : 
        null 
      }
    </div>
  )
}
