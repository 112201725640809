import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from "../Button"
import HighlightedMessage from "../HighlightedMessage"
import Attention from "../../images/sun.svg"

export function calculateTDEE(userData, formData) {

  const weightInPounds = formData.weight * 2.205
  const heightInInches = formData.height * 39.37

  const tdee = {}
  if (userData.sex.toLowerCase() === "masculino") {
    tdee.tdee = (66.473 + (6.23 * weightInPounds) + (12.7 * heightInInches) - (6.8 * userData.age)) * (+formData.activityLevel)
  } else {
    tdee.tdee = (665 + (4.35 * weightInPounds) + (4.7 * heightInInches) - (4.7 * userData.age)) * (+formData.activityLevel)
  }
  tdee.goal = 0.8 * tdee.tdee

  // for weight loss, do not use the current weight of the person to calculate protein intake
  // but the healthy (IMC of 25) body weight of that person
  const healthyWeightInKg = 25 * (formData.height) ** 2 // BMI of 25
  const healthyWeightInPounds = healthyWeightInKg * 2.205

  tdee.protein = healthyWeightInPounds

  tdee.proteinHypertrophyLow = formData.weight * 1.6 // between 1.6g and 2.2g per kg of body weight
  tdee.proteinHypertrophyHigh = formData.weight * 2.2

  tdee.tdeeHypetrophyLow = tdee.tdee + 200
  tdee.tdeeHypetrophyHigh = tdee.tdee + 400

  return tdee
}

export default function WeightLossForm({ addObjectiveToList, userAge, userSex }) {

  const phoneBreakpoint = useMediaQuery({ query: '(max-width: 520px)' })

  const [formData, setFormData] = React.useState({
    height: "", weight: "", activityLevel: ""
  })
  
  // TDEE: total daily energy expenditure
  const [tdeeCalculated, setTdeeCalculated] = React.useState(false)
  const [tdee, setTdee] = React.useState({})

  const [error, setError] = React.useState(null)

  const userRef = React.useRef()

  // go to the top of the page
  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault()

    if (formData.activityLevel === null || formData.activityLevel === "") {
      setError("Por favor, selecione um nível de atividade física")
      return
    }
    if (isNaN(formData.height) || isNaN(formData.weight)) {
      setError("Por favor, insira valores válidos para altura (em metros), e peso (em kilogramas). Use ponto para separar os números, por exemplo: 1.69")
      return
    }

    if (formData.height !== "" && formData.weight !== "") {
      setError(null)

      console.log('submitted')
      console.log(formData)

      setTdee(calculateTDEE({age: userAge, sex: userSex}, formData))

      setTdeeCalculated(true)

    } else {
      setError("Por favor, preencha todos os campos")
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const addObjectives = () => {
    addObjectiveToList([
      {
        "title": "Consumo diário de calorias",
        "content": `Até ${Math.round(tdee.goal)} calorias`,
        "manually_added": false,
      },
      {
        "title": "Consumo diário de proteínas",
        "content": `Cerca de ${Math.round(tdee.protein)} gramas`,
        "manually_added": false,
      }
      ], {
        "height": +formData.height,
        "weight": +formData.weight,
        "activity_level": +formData.activityLevel,
        "proteinTitle": "Consumo diário de proteínas",
        "caloryTitle": "Consumo diário de calorias",
        "proteinContent": Math.round(tdee.protein),
        "caloryContent": Math.round(tdee.goal)
      }
    )
  }

  const resetForm = () => {
    setTdeeCalculated(false)
    setFormData({
      height: "", weight: "", activityLevel: ""
    })
  }

  let tdeeResults = null
  if (tdeeCalculated) {
    tdeeResults = (
      <div>
        <p className="body"><strong>Em média você gasta diariamente </strong>{Math.round(tdee.tdee)} calorias</p>
        <p className="body top-margin-xs"><strong>Para perder peso, você pode consumir até </strong>{Math.round(tdee.goal)} calorias por dia</p>
        <p className="body top-margin-xs"><strong>Uma boa ideia também é consumir cerca de </strong>{Math.round(tdee.protein)} gramas de proteína diariamente</p>
        <p className="secondary-text top-margin-xs">
          Proteínas são usadas na construção dos músculos, ajudam a ganhar massa magra, aceleram o metabolismo e dão sensação de saciatez
        </p>
        <div>
          <Button type="primary top-margin-xs bottom-margin-xs" onClick={()=>addObjectives()}>Adicionar meta</Button>
          <Button type="tertiary left-margin-xs" onClick={()=>{resetForm()}}>Recalcular</Button>
        </div>
      </div>
    )
  }

  const userForm = (
    <>
      <p className="body">
        Para perder peso, você precisa consumir menos calorias do que você gasta.
        Um bom início pode ser consumir 20% menos.
      </p>
      <p className="body top-margin-xs">
        Calcule quantas calorias você normalmente gasta em um dia, e quantas calorias você pode consumir.
      </p>
      <p className="secondary-text top-margin-xs bottom-margin-xs">
        Adicionalmente, sugerimos que você trabalhe com um nutricionista para desenvolver um plano individual para você.
      </p>

      
      {(error) ?
        <div className="">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }

      <p className="body capitalize"><strong>Sexo: </strong>{userSex}</p>
      <p className="body top-padding-xs"><strong>Idade: </strong>{userAge}</p>

      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-xs">Altura (m)</p>
        <input
          className="input-text bottom-margin-xs"
          ref = {userRef}
          name="height"
          onChange={handleChange}
          type="text"
          value={formData.height}
        />

        <p className="body">Peso (kg)</p>
        <input
          className="input-text bottom-margin-xs"
          name="weight"
          onChange={handleChange}
          type="text"
          value={formData.weight}
        />

        <p className="body bottom-margin-xs">Nível de atividade física</p>
        <div className={(phoneBreakpoint) ? "row-radio-buttons-single bottom-margin-xs" : "row-radio-buttons-narrow bottom-margin-xs"}>
          <div className="closed-radio-button">
            <label className="body"><strong>Sedentário</strong>: Pouco ou nenhum exercício</label>
            <input
              name="activityLevel"
              id = "activityLevel0"
              onClick={handleChange}
              type="radio"
              value="1.15"
            />
          </div>
          <div className="closed-radio-button">
            <label className="body"><strong>Levemente ativo</strong>: Exercício leve 1-3x por semana</label>
            <input
              name="activityLevel"
              id = "activityLevel1"
              onClick={handleChange}
              type="radio"
              value="1.25"
            />
          </div>
          <div className="closed-radio-button">
            <label className="body"><strong>Moderadamente ativo</strong>: Exercício moderado 3-5x por semana</label>
            <input
              name="activityLevel"
              id = "activityLevel2"
              onClick={handleChange}
              type="radio"
              value="1.3"
            />
          </div>
          <div className="closed-radio-button">
            <label className="body"><strong>Altamente ativo</strong>: Exercício intenso 6-7x por semana</label>
            <input
              name="activityLevel"
              id = "activityLevel3"
              onClick={handleChange}
              type="radio"
              value="1.45"
            />
          </div>
        </div>

        <div className="top-margin-xs">
          <Button type="primary top-margin-xs bottom-margin-xs">
            Calcular 
          </Button>
        </div>
      </form>
    </>
  )

  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
      {(tdeeCalculated) ? tdeeResults : userForm }
    </div>
  )
}
