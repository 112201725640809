import React from 'react';
import LargeButtonLink from "../components/LargeButtonLink"
import forumIcon from "../images/forum-black.svg"
import pillIcon from "../images/pill-black.svg"
import FoodIcon from "../images/bowl-black.svg"
import ExerciseIcon from "../images/awards-black.svg"
import ForumWindow from './ForumWindow';

export default function FastAccessSection({ tabletBreakpoint, mobileBreakpoint, exercise, eating, id }) {

  mobileBreakpoint = true

  return (
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
      <div className="bottom-margin-s">
        <ForumWindow />
      </div>
      <div className={(mobileBreakpoint) ? 
      "large-button-container-mobile" : "large-button-container"}>
        <LargeButtonLink 
          icon=<img src={FoodIcon} alt="Botão para portal de alimentação"/>
          title="Alimentação"
          text="Adicione suas metas de alimentação e acompanhe seu progresso." 
          to={`/eating`}
          addTo="/eating/submit"
          addText='Adicionar refeição'
          mobileBreakpoint={mobileBreakpoint}
          message={(eating?.message && eating.message) ? eating.message : null}
        />
        <LargeButtonLink 
          icon=<img src={ExerciseIcon} alt="Botão para Fórum"/>
          title="Exercícios Físicos"
          text="Adicione e acompanhe a realização de suas atividades físicas."
          to={`/exercise`}
          addTo="/exercise/submit"
          addText='Adicionar exercício'
          mobileBreakpoint={mobileBreakpoint}
          message={(exercise?.message && exercise.message) ? exercise.message : null}
        />
        <LargeButtonLink 
          icon=<img src={pillIcon} alt="Botão para portal de saúde"/>
          title="Saúde"
          text="Adicione exames médicos e  informações de saúde" 
          to={`/health`}
          mobileBreakpoint={mobileBreakpoint}
          clsName="large-button-link-no-border"
        />
      </div>
    </div>
  )
}
