import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ProductSection from "../components/ProductSection"
import Carousel from "../components/Carousel"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Button from "../components/Button"
import LoginBlock from "../components/LoginBlock"
import AuthContext from '../context/AuthProvider'
import HomeThumbnails from "../components/Forum/HomeThumbnails"

export default function Home() {
  
  const [sliderIndex, setSliderIndex] = React.useState(2)
  
  const { auth, cookies } = React.useContext(AuthContext)
  const navigate = useNavigate()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })

  const images = [
    "health-okt.png",
    "familia-1.png", 
    "food-okt.png", 
    "exercise-okt.png", 
    "forum-okt.png"]

  const buttonsList = [
    "Saúde",
    "Família",
    "Alimentação",
    "Exercício",
    "Comunidade"
  ]
  
  React.useEffect(() => {
    if (auth.accessToken || cookies.get("accessToken")) {
      if (cookies.get("userId")) {
        navigate("/profile")
      } else {
        navigate("family")
      }
    }
  }, [])
  
  //<div className="bottom-margin-s">
  //    <h1 className="intra-page-title">Fórum</h1>
  //    <p className="body vertical-margin-s">O Fórum da Florence é uma comunidade construída para a troca de experiência sobre saúde. 
  //  Nesse espaço você pode tirar dúvidas, contar sua experiência e aprender com outras pessoas.</p>
  //    <Button type="secondary bottom-margin-s" link={true} to={'./forum'}>Visite o Fórum</Button>
  //
  //    <HomeThumbnails />
  //</div>
  const img1 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[0]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img2 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[1]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img3 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[2]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img4 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[3]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img5 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[4]}`)} alt="Screenshot demonstrating functionality of the software"/>
  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={tabletBreakpoint} menuShow={true}/>
      </div>

      <div className="container-simple">
        <section>
          <h1 className="florence-title top-margin-xl">Bem vindo a Florence, seu portal de saúde</h1>
        </section>
      </div>

      <div className="top-margin-m bottom-margin-l">
        <div className={(tabletBreakpoint) ? 
        "colorful-background-tablet vertical-padding-m" : "colorful-background vertical-padding-m"}>
          <div className="container-simple">
            <LoginBlock />
          </div>
        </div>
      </div>

      <div className="container-simple">
        <section>

          <ProductSection 
            title="Acompanhamento de alimentação" 
            text="Seja seu objetivo ganhar massa muscular, perder peso, controlar o colesterol, ou outro, a Florence ajuda você. 
            Acompanhe sua alimentação, quantidade de calorias e proteínas, e conte com sugestões da Florence para atingir suas metas."
            img="food-okt.png"
            breakpoint={tabletBreakpoint}
          />

          <ProductSection 
            title="Prática de atividades físicas" 
            text="Acompanhe e faça gestão de sua prática de exercício físico, tendo visão de sua evolução e de onde você quer chegar.
            A Florence te ajuda a atingir seus objetivos, de maneira saudável e segura."
            img="exercise-okt.png"
            breakpoint={tabletBreakpoint}
          />

          <ProductSection 
            title="Fórum" 
            text="Tire suas dúvidas sobre saúde, alimentação e exercícios físicos, e ajude outras pessoas respondendo as dúvidas delas, 
                compartilhando sua experiência e conhecimento."
            img="forum-okt.png"
            breakpoint={tabletBreakpoint}
          />

          <ProductSection 
            title="Sua saúde sob seu controle" 
            text="Tenha seus dados de saúde a seu alcance: Acompanhamento da tomada de medicação, 
                evolução do tratamento médico, resultados de exames e histórico de saúde.
                Compartilhe essas informações de maneira fácil e segura com seu médico."
            img="health-okt.png"
            breakpoint={tabletBreakpoint}
          />

          <ProductSection 
            title="Cuidando de sua família" 
            text="Tenha os dados e informações de saúde de toda sua família em um só lugar, e saiba como cada um está."
            img="familia-1.png"
            breakpoint={tabletBreakpoint}
          />

          <p className="intra-page-title top-margin-xl bottom-margin-s">Nossa missão é ajudar você a cuidar melhor de sua saúde.</p>

          <Button type="primary bottom-margin-xxl" link={true} to='/registration'>Cadastre-se</Button>
        </section>
      </div>

      <Footer border="footer-no-right-border"/>
    </>
  )
}
