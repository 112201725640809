import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Return from '../components/Forum/Return'
import { useNavigate } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { deleteDataFromApi } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import ExerciseThumbnail from '../components/Exercise/ExerciseThumbnail'

export default function ExerciseHighlight() {

  const { auth, cookies } = React.useContext(AuthContext);
  
  const {
    exercise,
    highlightedExercise,
    setHighlightedExercise,
    exerciseWeek, 
    exerciseWeekLoading,
    setExerciseWeek,
    setExerciseHistory,
    loadExerciseDataFromAPI 
  } = React.useContext(PortalContext);
  
  const navigate = useNavigate()
  
  React.useEffect(() => {
  
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      loadExerciseDataFromAPI()
    }
  }, []);
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const removeExercise = (id) => {
    console.log(`removing exercise ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'exercise', auth, cookies)
    setExerciseWeek(prev => prev.filter(item => item.id !== id))
    setExerciseHistory(prev => prev.filter(item => item.id !== id))
    setHighlightedExercise({error: true, error_message: "O exercício foi excluído com sucesso"})
  }
  
  const exercisesFiltered = exerciseWeek.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  exercisesFiltered.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date) - new Date(a.date);
  });
  
  if (!highlightedExercise) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Submetendo exercício...</p> 
      </div>
    )
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to='/exercise'/>

      <div className="top-margin-l">
        
        {(highlightedExercise.error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={highlightedExercise.error_message} /> :
          <EditableItemsCard2 
            clsName="bottom-margin-l" 
            title="Exercício adicionado"
            icons={false}
          >
            <div>
              <ExerciseThumbnail exercise={highlightedExercise} onRemove={()=>{removeExercise(highlightedExercise.id)}}/>
            </div> 
          </EditableItemsCard2>
        } 
        
        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Seus exercícios essa semana"
          icons={false}
        >
          <div>

            {exercisesFiltered.map(exercise => <ExerciseThumbnail key={exercise.id} exercise={exercise} icons={false}/>)}

            {(exercise.ids.length > exercisesFiltered.length && exerciseWeekLoading) ?
              <div className="icon-loading-middle-page top-margin-m">
                <p>Carregando seus exercícios...</p> 
              </div> : null }
          </div>
        </EditableItemsCard2>
      </div>
  </div>
  )
}


