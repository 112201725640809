import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
//import MenuColumn from "../components/MenuColumn"
//import ProfileBriefing from "../components/ProfileBriefing"
//import ProfileQuestions from "../components/ProfileQuestions"
import FastAccessSection from "../components/FastAccessSection"
//import FamilyIcon from "../images/family.svg"
//import LogOut from "../images/log-out.svg"
//import Account from "../images/account.svg"
//import Attention from "../images/sun.svg"
//import Food from "../images/bowl.svg"
//import Pill from "../images/pill.svg"
//import ProfileIcon from "../images/heart.svg"
//import Exercise from "../images/awards.svg"
//import FoodWhite from "../images/bowlw.svg"
//import PillWhite from "../images/pillw.svg"
//import ExerciseWhite from "../images/awardsw.svg"
//import Bulb from "../images/bulb.svg"
import { PortalContext } from '../AuthenticatedUser'

export default function Profile() {

  //const {id} = useParams()
  const location = useLocation()
  const { cookies, health, setHealth, exercise, eating, loadExerciseDataFromAPI, loadEatingDataFromAPI, loadHealthDataFromAPI } = React.useContext(PortalContext);

  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  //const laptopBreakpoint = useMediaQuery({ query: '(min-width: 1024px)' })
  //const desktopBreakpoint = useMediaQuery({ query: '(min-width: 1280px)' })

  const navigate = useNavigate()

  const name = cookies.get("userName") 

  cookies.set('pageOfInterest', null, { path: '/' })
  //cookies.set("userId", id, { path: '/' })

  React.useEffect(() => { 
    if (!cookies.get("userId")) {
      navigate("/family")
    } else {
      loadEatingDataFromAPI()
      loadExerciseDataFromAPI()
      loadHealthDataFromAPI(+cookies.get("userId"))
    }
  }, [])

  if (!exercise || !eating || !health) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }


  if (health[+cookies.get("userId")]) {
    if (health[+cookies.get("userId")].tutorial !== true) {
      const updatedUserHealth = health[+cookies.get("userId")]
      updatedUserHealth.tutorial = true
      setHealth(prev => ({...prev, [+cookies.get("userId")]: updatedUserHealth}))
      navigate("/tutorial")
    }
  }

  const content = (
      <section>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <h1 className="florence-title top-margin-xl bottom-margin-xl">{`Olá, ${name}!`}</h1>
      </div>
          
        
        {/*<ProfileQuestions id={id}/>*/}

        <FastAccessSection 
          tabletBreakpoint={tabletBreakpoint} 
          mobileBreakpoint={mobileBreakpoint} 
          id={+cookies.get("userId")} 
          eating={eating}
          exercise={exercise}
        />

        {/*
        <div className="container-simple">
          <ProfileBriefing type="warning" icon={Attention} header="Aviso" 
              body="Você ainda não ativou sua assistente de saúde Florence para WhatsApp. 
              Ela irá lembra-lo de seus medicamentos, exercícios físicos e checar sua alimentação."
              button="Ativar Assistente"/>

          <div className={desktopBreakpoint ? "content-two-columns": ""}>
            <p className="intra-page-title bottom-margin-xs">Portal de Saúde</p>
            <ProfileBriefing 
                message="Seu portal de saúde está um pouco vazio. 
                Você pode adicionar suas informações de saúde e exames médicos."           
                button="Portal de Saúde"
                link={true} to={`/health/${id}`}/>

            <p className="intra-page-title bottom-margin-xs">Exercícios Físicos</p>
            <ProfileBriefing 
                message="Seu histórico de exercícios físicos ainda está vazio, mas vamos preenchê-lo com o tempo."
                button="Exercícios Físicos" 
                link={true} to={`/exercise/${id}`}/>

            <p className="intra-page-title bottom-margin-xs">Alimentação</p>
            <ProfileBriefing 
                message="Seu histórico de alimentação ainda está vazio, mas vamos adicionar suas refeições todos os dias."
                button="Alimentação"
                link={true} to={`/eating/${id}`}/>
          </div>          
        </div>
        */}

        <br/>
      </section>
  )

//  const menuItems = laptopBreakpoint ? [
//    {icon: <img className="menu-icon" src={ProfileIcon}/>, selected: true,  label: "Início", link: "#", type: "Navegue" },
//    {icon: <img className="menu-icon" src={PillWhite}/>, label: "Dados de Saúde", link: "#", type: "Navegue" },    
//    {icon: <img className="menu-icon" src={ExerciseWhite}/>, label: "Exercícios Físicos", link: "#", type: "Navegue" },   
//    {icon: <img className="menu-icon" src={FoodWhite}/>, label: "Alimentação", link: "#", type: "Navegue" },
//    {icon: <img className="menu-icon" src={Bulb}/>, label: "Assistente de Saúde", link: "#", type: "Navegue" },
//    {icon: <img className="menu-icon" src={FamilyIcon}/>, label: "Família", link: "#", type: "Configurações" },
//    {icon: <img className="menu-icon" src={Account}/>, label: "Sua Conta", link: "#", type: "Configurações" },
//    {icon: <img className="menu-icon" src={LogOut}/>, label: "Sair", link: "#", type: "Configurações"}
//  ] : [
//   {element: <strong>Inicio</strong>, link: "#"},
//    {element: <>Dados de Saúde</>, link: "#"},    
//    {element: <>Exercícios Físicos</>, link: "#"},   
//    {element: <>Alimentação</>, link: "#"},
//    {element: <>Assistente de Saúde</>, link: "#"},
//    {element: <>Família</>, link: "#"},
//    {element: <>Sua Conta</>, link: "#"},
//    {element: <>Sair</>, link: "#"}
//  ] 

  //    {
  //    laptopBreakpoint ? 
  //      <div className="lateral-menu">
  //        <MenuColumn menuItems={menuItems} user={name}/>
  //        <div className={desktopBreakpoint ? "container container-no-border container-two-columns":"container container-no-border"}>
  //          <Header menuShow={false}/>
  //
  //          {content}
  //
  //        </div>
  //      </div> 
  //    :
  return (
    <>
      <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} menuItems="profile" id={+cookies.get("userId")} menuShow={true}/>
      </div>

      {content}
      </>
    </>
  )
}
