import React from 'react'
import { useNavigate } from "react-router-dom"
import RemoveIcon from '../../images/remove.svg'
import EditIcon from '../../images/edit.svg'
import { format } from "date-fns"
import Button from '../Button'
import HighlightedMessage from '../HighlightedMessage'
import Attention from "../../images/sun.svg"
import { putRequestToApi } from '../../api'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'

export default function FoodThumbnail({ food, onRemove, onEdit=true, icons=true }) {

  const [edit, setEdit] = React.useState(false)
  const [status, setStatus] = React.useState('idle')
  const [error, setError] = React.useState(null)
  
  const { 
    mealsToday, 
    mealsHistory,
    setMealsToday,
    setMealsHistory,
    highlightedMeal,
    setHighlightedMeal
  } = React.useContext(PortalContext);
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const navigate = useNavigate()

  const [formData, setFormData] = React.useState({
    name: food.name,
    calories: food.calories,
    proteins: food.proteins
  })

  let imageUrl = null
  if (food.hasImage) {
    imageUrl = window.URL.createObjectURL(food.image)
  }
  
  async function submitEdition() {

    console.log(formData)
    setError(null)
    if (formData.name === "" || formData.calories === "" || formData.proteins === "") {
      setError("Por favor, preencha todos os campos")
      return
    }
    if (!(+formData.calories || +formData.calories === 0) || !(+formData.proteins || +formData.proteins === 0)) {
      setError("Os valores de calorias e proteínas devem ser apenas números. Exemplo: 49")
      return
    }
    setStatus("submitting")
    try {
      const requestBody = {
        record_id: food.id,
        id: +cookies.get("userId"), 
        item: {
          name: formData.name,
          calories: formData.calories,
          proteins: formData.proteins
        }
      }

      const data = await putRequestToApi(requestBody, 'food', auth, cookies)
      
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 


    } catch (err) {
      console.log("Error loading data")
      navigate('/loginrequired')
    } finally {

      const mealUpdated = { ...food, name: formData.name, calories: formData.calories, proteins: formData.proteins }

      //mealsToday, 
      //mealsHistory,
      //setMealsToday,
      //setMealsHistory,

      const mealsHistoryUpdated = mealsHistory.filter(item => item.id !== food.id)
      setMealsHistory([...mealsHistoryUpdated, mealUpdated])

      if (mealsToday.filter(item => item.id === food.id).length > 0) {
        const mealsTodayUpdated = mealsToday.filter(item => item.id !== food.id)
        setMealsToday([...mealsTodayUpdated, mealUpdated])
      }

      if (highlightedMeal && highlightedMeal.id === food.id) {
        setHighlightedMeal(prev => ({...prev, name: mealUpdated.name, calories: mealUpdated.calories, proteins: mealUpdated.proteins }))
      }
      
      setEdit(false)
      setStatus("idle")
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    submitEdition()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  if (edit) {
    return (
      <div className="editable-item-for-card-green top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">     
          {(error) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />
            </div> :
            null
          }
        
          <form onSubmit={handleSubmit}>
            <div>
              <div className="bottom-margin-xs">
                <p className="body">Descrição da refeição</p>
                <textarea id="answer" name="name" className="profile-question-form form-full-width shorter-form"  type="text" 
                    placeholder="Descrição da refeição" onChange={handleChange} value={formData.name}/>
              </div>
              <div className="bottom-margin-xs top-margin-xs">
                <p className="body">Quantidade de calorias</p>
                <textarea id="answer" name="calories" className="profile-question-form form-full-width shorter-form" type="text" 
                    placeholder="Quantidade de calorias" onChange={handleChange} value={formData.calories}/>
              </div>
              <div className="bottom-margin-xs top-margin-xs">
                <p className="body">Quantidade de proteínas</p>
                <textarea id="answer" name="proteins" className="profile-question-form form-full-width shorter-form" type="text" 
                    placeholder="Quantidade da proteínas" onChange={handleChange} value={formData.proteins}/>
              </div>
              <Button type="primary">{(status==="idle") ? "Editar" : "Enviando..."}</Button>
              <Button type="secondary left-margin-xs" onClick={()=>setEdit(prev=>!prev)}>{"Cancelar"}</Button>
          </div>
          </form>
      </div>
    )
  }

  return (
    <div className="editable-item-for-card-green top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">     
      <div className='editable-item-for-card-header'>
        <div className='food-image-title-row'>
          {(food.hasImage) ?
          <img className="food-image" src={imageUrl} alt="Food image"/> : null }
          <div>
            <p className="body">{food.name}</p> 
            <p className="secondary-text">{food.calories} calorias</p> 
            <p className="secondary-text">{food.proteins} g de proteína</p> 
            <p className="secondary-text">Adicionado em {(food.created_at) ? format(food.created_at,"d/M/yyyy") : format(food.date, "d/M/yyyy")}</p>
          </div>
        </div>
        {(icons) ? 
        <div className="row-svg-icons left-margin-xs">
          {(onEdit) ? 
          <button onClick={()=>{setEdit(prev => !prev)}} className='carousel-btn'>
            <img className="" src={EditIcon}/>
          </button> : null }

          <button onClick={()=>{onRemove()}} className='carousel-btn'>
            <img className="" src={RemoveIcon}/>
          </button>
        </div> : null}
      </div>
    </div>
  )
}
