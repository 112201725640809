import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import { PortalContext } from '../AuthenticatedUser'
import AuthContext from '../context/AuthProvider'
import { postRequestToApi } from '../api';
import Return from '../components/Forum/Return';
import EditableItemsCard2 from '../components/EditableItemsCard2';
import EditableItemForCard2 from '../components/EditableItemForCard2';
import EditableItemForCardEditSelection from '../components/EditableItemForCardEditSelection';
import EditableItemForCardDate from '../components/EditableItemForCardDate';
import Attention from "../images/sun.svg"
import HighlightedMessage from '../components/HighlightedMessage'


export default function UserAccount() {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { health, loadHealthDataFromAPI, setReload } = React.useContext(PortalContext);

  const [ message, setMessage ] = React.useState(null)

  const [dataModified, setDataModified] = React.useState(0)
  const [passwordModified, setPasswordModified] = React.useState(0)
  const [userHealth, setUserHealth] = React.useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    sex: '',
    email: '',
    password: '',
    subscribed: ''
  })
  
  const navigate = useNavigate()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
 
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/health', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadHealthDataFromAPI(+cookies.get("userId"))
    }
  }, []);
  
  React.useEffect(() => {
    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        firstName: `${health[id].first_name}`,
        lastName: `${health[id].last_name}`,
        birthDate: `${health[id].birthdate}`,
        sex: `${health[id].sex}`,
        email: `${health[id].email}`,
        subscribed: (health[id].subscribed === true) ? "Sim" : "Não"
        })
    }

  }, [health])
  
  React.useEffect(() => {
    if (dataModified > 0) {

      cookies.set("userName", userHealth.firstName, { path: '/' })

      const body = {
        "id": +cookies.get("userId"),
        "first_name": userHealth.firstName,
        "last_name": userHealth.lastName,
        "birth_date": userHealth.birthDate,
        "sex": userHealth.sex,
        "email": userHealth.email,
        "subscribed": (userHealth.subscribed === "Sim") ? true : false
      }

      postRequestToApi(body, 'alterprofile', auth, cookies) 
      
      setMessage("Suas modificações foram submetidas com sucesso. A atualização de todas as páginas do portal pode levar alguns minutos")
    }
  }, [dataModified])
  
  React.useEffect(() => {
    if (passwordModified > 0) {

      const body = {
        "id": +cookies.get("userId"),
        "password": userHealth.password
      }
      setMessage("Sua senha foi alterada com sucesso!")

      postRequestToApi(body, 'alterpassword', auth, cookies) 
    }
  }, [passwordModified])
  
  const editUserField = (name, value) => {

    // if value is empty, it does not enter the if statement
    if (value) {
      setUserHealth(prev => ({...prev, [name]: value}))
      
      setDataModified(prev => prev + 1)
    }
  } 

  const editPassword = (name, value) => {

    // if value is empty, it does not enter the if statement
    if (value) {
      setUserHealth(prev => ({...prev, [name]: value}))
      setPasswordModified(prev => prev + 1)
    }
  }

  return (
    (!userHealth.firstName) ? 
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>Carregando página...</p> 
    </div>
    :  
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
      </div>
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

          <div className="top-margin-xl bottom-margin-xs">
            <Return to={`/profile`} backTo="Página inicial"/>
          </div>

          <p className="florence-title bottom-margin-l">Minha Conta</p>
        
          <div className="bottom-margin-m">
            {(message) ?
              <div className="bottom-margin-m">
                <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={message} />
              </div>
              : null }
          </div>

          <EditableItemsCard2 
            clsName="bottom-margin-l"
            title="Perfil"
            icons={false}
          >
            <EditableItemForCard2 edit={false} inputType="email" userField={["email"]} editUserField={editUserField} placeholder={["Digite e-mail"]}>
              <p className="body"><strong>E-mail: </strong>{userHealth.email}</p>
            </EditableItemForCard2>
            <EditableItemForCard2 edit={true} userField={["firstName"]} editUserField={editUserField} placeholder={["Digite seu nome"]}>
              <p className="body"><strong>Nome: </strong>{`${userHealth.firstName}`}</p>
            </EditableItemForCard2>
            <EditableItemForCard2 edit={true} userField={["lastName"]} editUserField={editUserField} placeholder={["Digite seu sobrenome"]}>
              <p className="body"><strong>Sobrenome: </strong>{`${userHealth.lastName}`}</p>
            </EditableItemForCard2>
            <EditableItemForCardDate edit={true} userField={["birthDate"]} editUserField={editUserField} >
              <p className="body"><strong>Data de nascimento: </strong>{userHealth.birthDate}</p>
            </EditableItemForCardDate>
          <EditableItemForCardEditSelection
                edit={true} 
                userField={["sex"]}
                editUserField={editUserField} 
                responseOptions={["Masculino", "Feminino", "Outro"]}
                gridClass="content-two-columns">
              <p className="body"><strong>Sexo: </strong>{userHealth.sex}</p>
            </EditableItemForCardEditSelection>
          <EditableItemForCardEditSelection
                edit={true} 
                userField={["subscribed"]}
                editUserField={editUserField} 
                responseOptions={["Sim", "Não"]}
                gridClass="content-two-columns">
              <p className="body"><strong>Receber lembretes por e-mail: </strong>{userHealth.subscribed}</p>
            </EditableItemForCardEditSelection>
            <EditableItemForCard2 edit={true} inputType="password" userField={["password"]} editUserField={editPassword} placeholder={["Digite sua nova senha"]}>
              <p className="body"><strong>Alterar senha </strong></p>
            </EditableItemForCard2>
          </EditableItemsCard2>
        </div>
      </>
    </>
  )
}
