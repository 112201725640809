import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from './Button'
import HighlightedMessage from './HighlightedMessage'
import forumIcon from "../images/forum-black.svg"
import Author from './Forum/Author'
import PostLikes from './Forum/PostLikes'
import PostComments from './Forum/PostComments'
import AuthContext from '../context/AuthProvider'
import chevronRight from "../images/chevron-right-solid.svg"
import chevronLeft from "../images/chevron-left-solid.svg"
import { ForumContext } from '../forum/ForumData'
import Tag from './Tag'
import CommentarySection from './Forum/CommentarySection'

export default function ForumWindow() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { cookies } = React.useContext(AuthContext);
  const { 
    postProfileIndex, 
    setPostProfileIndex,
    profileForumData, 
    profileLoading, 
    profileHasMore, 
    loadProfilePost
  } = React.useContext(ForumContext)

  const [showAnswers, setShowAnswers] = React.useState(false)
  const [allowAnswer, setAllowAnswer] = React.useState(false)
 
  React.useEffect(()=>{
    if (postProfileIndex===0) {
      loadProfilePost()
      //loadForumData(true, postProfileIndex) // load current post
    }
    loadProfilePost() // load next post
  }, [postProfileIndex])


  const visibleItems = []
  const postsToRender = []
  
  profileForumData.posts.forEach(post => {
    if (visibleItems.includes(post.id)) {
      return null
    } else {
      visibleItems.push(post.id)
      postsToRender.push(post)
    }
  })

  if (!profileHasMore) {
    return (
      <div className="large-button-link-no-border">
        <img src={forumIcon} alt="botão para fórum"/>
        <p className="body">Fórum</p>
        <div className="icon-loading-middle-page-short">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Você leu todas perguntas até agora!</p> 
        </div>
      </div>
    )
  }

  if (!profileForumData || postsToRender.length === 0) {
    return (
      <div className="large-button-link-no-border">
        <img src={forumIcon} alt="botão para fórum"/>
        <p className="body">Fórum</p>
        <div className="icon-loading-middle-page-short top-margin-m">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando pergunta...</p> 
        </div>
      </div>
    )
  }
   
 if (!postsToRender[postProfileIndex]) {
    return (
      <div className="large-button-link-no-border">
        <img src={forumIcon} alt="botão para fórum"/>
        <p className="body">Fórum</p>
        <div className="icon-loading-middle-page-short top-margin-m">
          <img className="small-logo-nav loading-icon" 
              src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando pergunta...</p> 
        </div>
      </div>
    )
  }
 
  let imageUrl = null
  if (postsToRender[postProfileIndex].hasImage) {
    imageUrl = window.URL.createObjectURL(postsToRender[postProfileIndex].image)
  }

  if (!postsToRender[postProfileIndex].content) {
    return null
  }

  const prevPost = () => {
    if (postProfileIndex > 0) {
      setPostProfileIndex(prev => prev-1)
    } 
  }
  const nextPost = () => {
    setShowAnswers(false)
    setAllowAnswer(false)
    setPostProfileIndex(prev => prev+1)
  }

  return (
    <div className="large-button-link-no-border">
      <img src={forumIcon} alt="botão para fórum"/>
      <p className="body">Fórum</p>

      <div className="post-thumbnail top-padding-s">
        <div className="carousel">
          <div id={postsToRender[postProfileIndex].id}  key={postsToRender[postProfileIndex].id} className="forum-message bottom-margin-xs">
            <Author profilePage={true}>{postsToRender[postProfileIndex]}</Author>
            <div className="thumbnail-link">
              <p className="heading4 primary-color bottom-margin-xs">{postsToRender[postProfileIndex].title}</p>
              <p className="body primary-color content bottom-padding-xs">{(postsToRender[postProfileIndex].content.length > 1100) ? 
                postsToRender[postProfileIndex].content.slice(0,300) + "..." : postsToRender[postProfileIndex].content }</p>
              {(postsToRender[postProfileIndex].hasImage) ? 
                <img className="post-image" src={imageUrl} alt="Post image"/>
                : null 
              }
            </div>
            {(postsToRender[postProfileIndex].content.length > 300) ? 
              <Button 
                type="tertiary blue-message-background bottom-margin-xs" 
                link={true} 
                to={`/forum/${postsToRender[postProfileIndex].id}`}
                stateValue="/profile"
              >
                Continuar lendo
              </Button> :  null
            }
            <div className="tags-row">
              {postsToRender[postProfileIndex].tags.map((tag, i) => 
                <Tag key={i} color="blue">{tag}</Tag>)
              }
            </div>
          </div>
          <button className="svg-btn" onClick={()=>{nextPost()}}>
            <img className="chevron" src={chevronRight} alt="Right arrow to change image"/>
          </button>
        </div>
        <div className="post-stats bottom-padding-xs">
          <PostLikes 
            postId={postsToRender[postProfileIndex].id} 
            foundUseful={(cookies.get("userId") && postsToRender[postProfileIndex].voted_by) ? 
            postsToRender[postProfileIndex].voted_by.includes(cookies.get("userId")) : false} 
            qtyPeopleFoundUseful={postsToRender[postProfileIndex].upvotes} />
          <PostComments qtyComments={postsToRender[postProfileIndex].comments_qty} /> 
        </div>

      <div>
        <Button type="primary top-margin-xs right-margin-xs"
          onClick={()=>{setAllowAnswer(prev=>!prev)}}>
            {(allowAnswer) ? "Mostrar menos" : "Responder"}
        </Button>

        <Button
            type={(showAnswers) ? "secondary top-margin-xs right-margin-xs" : "secondary top-margin-xs right-margin-xs bottom-margin-s" }
            link={true} to="/forum/write" stateValue="/profile">
            Fazer pergunta
        </Button>

        {(allowAnswer) ?
          <div className="top-margin-xs bottom-margin-xs">
          <CommentarySection 
              postId={postsToRender[postProfileIndex].id} 
              comments={postsToRender[postProfileIndex].comments}
              profileForumData={profileForumData}
              allowCommentFeed={true}
              allowComment={true}
              />
            </div> 
            : null
        }
      </div>
    </div>
  </div>
  )
}
