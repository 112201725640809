import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import EatingHistory from "../components/EatingHistory"
import PlotCard from "../components/PlotCard"
import Return from "../components/Forum/Return"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import EditableItemForCard2 from '../components/EditableItemForCard2'
import { postRequestToApi, putRequestToApi, deleteDataFromApi } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import { format, differenceInDays } from "date-fns"
import UserBmiCard from '../components/UserBmiCard'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import Bulb from "../images/bulbDark.svg"
import Button from '../components/Button'
import RemoveIcon from '../images/remove.svg'

import WeightLossForm from '../components/Eating/WeightLossForm'
import FoodThumbnail from '../components/Eating/FoodThumbnail'
import EnergyExpenditureCard from '../components/Eating/EnergyExpenditureCard'
import EatingObjectiveCard from '../components/Eating/EatingObjectiveCard'

export default function Eating() {

  //const {id} = useParams()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const { 
    health, 
    eating, 
    setEating, 
    mealsToday, 
    mealsTodayLoading,
    mealsHistory,
    setMealsToday,
    setMealsHistory,
    loadHealthDataFromAPI, 
    loadEatingDataFromAPI, 
    auth, 
    cookies } = React.useContext(PortalContext);

  const [dataModified, setDataModified] = React.useState(0)
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null
  })
  const [historyItemsData, setHistoryItemsData] = React.useState(null)
  //const [weight, setWeight] = React.useState(null)
  const [showBodyMeasures, setShowBodyMeasures] = React.useState(false)
  const [showWeightLoss, setShowWeightLoss] = React.useState(false)

  const [addObjective, setAddObjective] = React.useState(false)
  const [addWeight, setAddWeight] = React.useState(false) 

  const [errorHeight, setErrorHeight] = React.useState(null)
  const [errorWeight, setErrorWeight] = React.useState(null)

  const navigate = useNavigate()
  const location = useLocation()

  //console.log(location)

  // go to the top of the page
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadEatingDataFromAPI()
      loadHealthDataFromAPI(+cookies.get("userId"))
      //loadWeightDataFromAPI()
    }
    if (eating && eating.goals !== null && eating.goals.length === 0) {
      setAddObjective(true)
    }
    if (eating && eating.weights !== null && eating.weights.length === 0) {
      setAddWeight(true)
    }
  }, []);
  
  React.useEffect(() => {
    if (eating && eating.goals !== null && eating.goals.length === 0) {
      setAddObjective(true)
    }
    if (eating && eating.weights !== null && eating.weights.length === 0) {
      setAddWeight(true)
    }
  }, [eating]);

  //console.log(eating)

  React.useEffect(() => {
    //console.log(health)

    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        age: `${health[id].age}`,
        eatingGoals: health[id].eating_goals ? `${health[id].eating_goals}` : 'Nenhuma por enquanto',
        dietRecord: health[id].diet_record ? 
          health[id].diet_record.map(item => ({
            id: item.id, date: new Date(item.date), description: item.description, grade: item.grade
        })) : [],
        smoking: health[id].smoking_status ? `${health[id].smoking_status}` : 'Não informado',
        height: health[id].height ? `${health[id].height}` : 'Não informado',
        weight: health[id].weight ? `${health[id].weight}` : 'Não informado',
        bmi: health[id].bmi ? `${health[id].bmi}` : null,
        nextAppointment: health[id].next_appointment ? `${health[id].next_appointment}` : 'Não informado',
        organ_donation: health[id].organ_donation ? health[id].organ_donation : 'Não declarado',
        blood_type: health[id].organ_donation ? health[id].blood_type : 'Não informado',
        sus_number: health[id].sus_number ? health[id].sus_number : 'Não informado',
        emergency_contact: (health[id].emergency_contact.name)
          ? health[id].emergency_contact : {name: "Não informado", phone_number: null},
        objectives: [],
        activityLevel: null
      })

    }
  }, [health])
 
  function addObjectiveToList(newObjectives, requestBody) {

    const objectivesArray = eating.goals.filter(item => item.manually_added)
    const newObjectivesArray = objectivesArray.concat(newObjectives)

    setEating(prev => ({...prev, goals: newObjectivesArray}))

    // upload to api
    postRequestToApi(
      {
        "id": +cookies.get("userId"),
        "exam_data": requestBody
      }, 'food/goals', auth, cookies)

    setShowWeightLoss(false)
  }

  //console.log(userHealth)

  React.useEffect(() => {

    let superHealthy = 0
    let healthy = 0
    let attention = 0

    if (userHealth.dietRecord) {
      userHealth.dietRecord.forEach((item) => {
        if (differenceInDays(new Date(), item.date) <= 30) {
          if (item.grade === "Super saudável") {
            superHealthy += 1
          } else if (item.grade === "Saudável") {
            healthy += 1
          } else if (item.grade === "Atenção") {
            attention += 1
          }
        }
      })
    }
    
    setHistoryItemsData([
      {"class": "Super saudável", "colour": "green", "value": superHealthy},
      {"class": "Saudável", "colour": "blue", "value": healthy},
      {"class": "Atenção", "colour": "red", "value": attention}
    ])
  }, [userHealth])

  const deleteObjective = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/goals', auth, cookies)

    const updatedGoals = eating.goals.filter(item => item.id !== id)  
    setEating(prev => ({...prev, goals: updatedGoals}))
  }

  const addObjectiveManually = (content) => {

    const requestBody = {
      "id": +cookies.get("userId"),
      "name": content.objective
    }

    putRequestToApi(requestBody, 'food/goals', auth, cookies)

    const updatedGoals = eating.goals
    updatedGoals.push({
      //"id": eating.goals.length,
      "title": null,
      "content": content.objective
    })
    setEating(prev => ({...prev, goals: updatedGoals}))
  }

  const addWeightManually = (content) => {
  
    if (+content.weight) {
      setErrorWeight(null)

      const requestBody = {
        "id": +cookies.get("userId"),
        "name": content.weight
      }
      putRequestToApi(requestBody, 'weights', auth, cookies)

      let updatedWeight = eating.weights
      updatedWeight.push({
        value: content.weight, date: new Date()
      })

      setEating(prev => ({...prev, weights: updatedWeight}))

    } else {
      setErrorWeight("Insira um número válido para o peso. Utilize ponto ao invés de vírgula. Por exemplo: 70.5")
      return
    }

  }

  React.useEffect(() => {
    if (dataModified > 0) {
      //console.log('user health changed, sending new data to API')

      const bodyRequest1 = {
        "id": +cookies.get("userId"),
        "smoking_status": userHealth.smoking,
        "height": userHealth.height,
        "weight": userHealth.weight,
        "next_appointment": userHealth.nextAppointment,
        "sus_number": userHealth.sus_number,
        "blood_type": userHealth.blood_type,
        "organ_donation": userHealth.organ_donation,
        "emergency_contact": userHealth.emergency_contact,
      }

      putRequestToApi(bodyRequest1, 'userhealth', auth, cookies)

      const bodyRequest2 = {
        "id": +cookies.get("userId"),
        "eating_goals": userHealth.eatingGoals
      }

      putRequestToApi(bodyRequest2, 'usereating', auth, cookies) 
    }
  }, [dataModified])

  const addItemtoArray = (value) => {

    if (Object.keys(value).length !== 0) {
    
      setUserHealth(prev => {
        const newArray = prev.dietRecord
        newArray.push(value)
        return {...prev, dietRecord: newArray}
      })

      const body = {
        "id": +cookies.get("userId"),
        "name": 'dietRecord',
        "item": { description: value.description, grade:value.grade, date: format(value.date,"M/d/yyyy") }
      }
      //console.log(body)
      postRequestToApi(body, 'userhealthlist', auth, cookies)
      
    }
  }

  const removeItemFromArray = (itemId) => {
    //console.log('*')
    setUserHealth(prev => {
      const newArray = prev.dietRecord

      const index = newArray.findIndex(item => item.id===itemId)

      const body = {
        "id": +cookies.get("userId"),
        "name": 'dietRecord',
        "record_id": itemId
      }

      deleteDataFromApi(body, 'userhealthlist', auth, cookies)

      newArray.splice(index, 1)
      return {...prev, dietRecord: newArray}
    })
  }

  const editUserField = (name, value) => {

    if (value) {

      if (name === "height") {
        if (+value) {
          setErrorHeight(null)

          if (+userHealth.weight) {
            const bmi = userHealth.weight / (value * value)
            setUserHealth(prev => ({...prev, bmi: `${parseFloat(bmi).toFixed(1)}`}))
          }

        } else {
          setErrorHeight("Insira um número válido para a altura. Utilize ponto ao invés de vírgula. Por exemplo: 1.80")
          return
        }
      } else if (name === "weight") {
        if (+value) {
          setErrorWeight(null)

          if (+userHealth.height) {
            const bmi = value / (userHealth.height * userHealth.height)
            setUserHealth(prev => ({...prev, bmi: `${parseFloat(bmi).toFixed(1)}`}))
          }

        } else {
          setErrorWeight("Insira um número válido para o peso. Utilize ponto ao invés de vírgula. Por exemplo: 70.5")
          return
        }
      }

      setUserHealth(prev => ({...prev, [name]: value}))
      
      setDataModified(prev => prev + 1)
    }
  }


  //async function loadWeightDataFromAPI() {
  //  try {
  //    const apiData = await getDataFromApi('weights', auth, cookies, {"id": +cookies.get("userId")})

  //    setWeight(apiData.weights.map(item => ({
  //      value: +item.value, date: new Date(item.date)
  //      })
  //    ))
  //  } catch (err) {
  //    console.log(err)
  //  }
  //}

  const removeFood = (id) => {
    console.log(`removing food ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'food', auth, cookies)
    setMealsToday(prev => prev.filter(item => item.id !== id))
    setMealsHistory(prev => prev.filter(item => item.id !== id))
  }
  
  const removeWeight = (id) => {
    console.log(`removing weight ${id}`)
    deleteDataFromApi({"id": +cookies.get("userId"), "item_id": +id}, 'weights', auth, cookies)
    const updatedWeights = eating.weights.filter(item => item.id !== id)
    setEating(prev => ({...prev, weights: updatedWeights}))
  }

  const userBmiCard = <UserBmiCard bmi={userHealth.bmi}/>

  const mealsFiltered = mealsToday.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  const mealsHistoryFiltered = mealsHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)

  //<ProfileBriefing type="message" icon={Attention} header="Mensagem" 
  //          body="Você comeu frutas pelo menos 3 vezes na semana, 
  //          mais um pouquinho para atingir seu objetivo!"
  //          />
  //
  //
  //
  //
  //      <EditableItemsCard2 
  //        clsName="bottom-margin-l"
  //        title="Perda de peso"
  //        icons={true}
  //        userAdd={false}
  //        toggleCard={()=>setShowWeightLoss(prev=>!prev)}
  //        showCard={showWeightLoss}
  //      >
  //        <WeightLossForm addObjectiveToList={addObjectiveToList} userAge={eating.age} userSex={eating.sex}/>
  //      </EditableItemsCard2>
  //
  return (
    (!userHealth.name || !eating) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    :
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
      </div>

      <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

        <div className="top-margin-xl bottom-margin-xs">
          <Return to={`/profile`} backTo="Página inicial"/>
        </div>  

        <p className="florence-title bottom-margin-l">Alimentação</p>

        <div className="bottom-margin-m">

          {(location.state?.message) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={location.state.message} />
            </div>
            : null }

          <div>
            <Button type="primary" link={true} to="/eating/submit">Adicionar nova refeição</Button>
          </div>

          <div className="content-two-columns top-margin-m">
            <Link className="thumbnail-link" to="/eating">
              <p className="body"><strong>Acompanhamento</strong></p>
            </Link>
            <Link className="thumbnail-link" to="/eating/history">
              <p className="body">Histórico</p>
            </Link>
          </div>
          <div className="progress-bar bottom-margin-m">
            <div className="rectangle full"></div>
            <div className="rectangle empty"></div>
          </div>

        </div>

        {(eating?.message) ?
          <div className="bottom-margin-m">
            <HighlightedMessage icon={Bulb} type="message" header="Mensagem da Florence" body={eating.message} />
          </div>
          : null } 
        
        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Seus objetivos"
          icons={true}
          addCard={()=>setAddObjective(prev=>!prev)}
        >
          <div>
            {(addObjective) ? 
                <EatingObjectiveCard setAddCard={()=>setAddObjective(prev=>!prev)} />
              : null}
            {
              (eating && eating.goals.length > 0) ? eating.goals.map((item, i) => (
                <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                  <div className='editable-item-for-card-header'>
                    {(item.title && item.title !== "") ? 
                    <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p> : 
                    <p key={i} className="body">{item.content}</p> } 
                    {(item.id) ?
                    <div className="row-svg-icons left-margin-xs">
                      <button onClick={()=>{deleteObjective(item.id)}} className='carousel-btn'>
                        <img className="" src={RemoveIcon}/>
                      </button>
                    </div> : null }
                  </div>
                </div>
              )) : null
            }
          </div>
        </EditableItemsCard2>
        
        <div className="bottom-margin-l">
          <EnergyExpenditureCard />
        </div>

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Sua alimentação hoje"
          icons={false}
        >
          <div>
            <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p className="body"><strong>Consumo de calorias: </strong>{Math.round(eating.calories)} cal</p> 
              </div>
            </div>
            <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p className="body"><strong>Consumo de proteínas: </strong>{Math.round(eating.proteins)} g</p> 
              </div>
            </div> 

            {mealsFiltered.map(food => <FoodThumbnail key={food.id} food={food} onRemove={() => removeFood(food.id)}/>)}


            {(eating.ids.length > mealsFiltered.length && mealsTodayLoading) ?
              <div className="icon-loading-middle-page top-margin-m">
                <p>Carregando suas refeições...</p> 
              </div> : null }
          </div>
        </EditableItemsCard2>

        <EditableItemsCard2 
          clsName="bottom-margin-l" 
          title="Acompanhamento de peso"
          icons={true}
          addCard={()=>setAddWeight(prev=>!prev)}
        >
          { (errorWeight) ?
            <div className="container-simple">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorWeight} />
            </div> : null
          }
          {(addWeight) ? 
              <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddWeight(prev=>!prev)} 
                addItemSubmit={(name, value) => addWeightManually(value)}
                dictFields={["weight"]} userField={["weight"]} 
                placeholder={["Adicione o valor do seu peso, em kg. Exemplo: 75.5"]}/>
            : null}
          {eating.weights.map(item => (
            <div key={item.id}>
              <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  <p className="body">{`Peso: ${item.value} kg`}</p>
                  {(item.id) ? <div className="row-svg-icons left-margin-xs">
                    <button onClick={()=>{removeWeight(item.id)}} className='carousel-btn'>
                      <img className="" src={RemoveIcon}/>
                    </button>
                  </div> : null }
                </div>
                <p className="secondary-text">{`Registrado em ${format(item.date,"d/M/yyyy")}`}</p>
              </div>
            </div>
          ))}
        </EditableItemsCard2>

      </div>
    
      </>
    </>
  )
}


//      <div className="colorful-background top-margin-l bottom-margin-l">
//        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
//          <EatingHistory 
//            calendarItems={userHealth.dietRecord} 
//            title={'Diário de alimentação '} 
//            tagged={true}
//            addItemtoArray={addItemtoArray}
//           removeItemFromArray={removeItemFromArray}
//         />
//        </div>
//
//        <div className={"container-simple"}>
//          {(historyItemsData) ? 
//            <PlotCard type="bar" title="Últimas 30 refeições" data={historyItemsData}/> :
//            null
//          }
//        </div>
//
//        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
//          <div className={(tabletBreakpoint) ? "portal-central-column nav-center-align" : ""}>
//            <EditableItemsCard2 
//              clsName="bottom-margin-l"
//              title="Medidas corporais"
//              icons={true}
//              userAdd={false}
//              toggleCard={()=>setShowBodyMeasures(prev=>!prev)}
//              showCard={showBodyMeasures}
//            >
//              { (errorHeight) ?
//                <div className="container-simple">
//                  <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorHeight} />
//                </div> : null
//              }
          //    { (errorWeight) ?
        //        <div className="container-simple">
      //            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorWeight} />
    //            </div> : null
   //           }
   //           <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["height"]}
//                placeholder={["Sua altura em metros"]}>
          //      <p className="body"><strong>Altura (m): </strong>{userHealth.height}</p>
        //      </EditableItemForCard2>
      //        <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["weight"]}
    //            placeholder={["Seu peso em kg"]}>
  //              <p className="body"><strong>Peso Atual (kg): </strong>{userHealth.weight}</p>
//              </EditableItemForCard2>
        //      {userBmiCard}
      //      </EditableItemsCard2>
    //      </div>
  //      </div>
//
  //      <div className={"container-simple"}>
//          {(weight) ?
//            <PlotCard type="timeline" title="Acompanhamento de peso" data={weight}/> :
//            null }
//        </div>
//      </div>
