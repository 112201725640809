import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import { format } from "date-fns"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import ExerciseThumbnail from '../components/Exercise/ExerciseThumbnail'

export default function SubmitExercise() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { exerciseHistory, setHighlightedExercise } = React.useContext(PortalContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [formData, setFormData] = React.useState({description: ""})
  const [postImage, setPostImage] = React.useState(null)
  const [status, setStatus] = React.useState("idle")

  const [error, setError] = React.useState(null)

  const [historySize, setHistorySize] = React.useState(3)

  const navigate = useNavigate()
  const location = useLocation()

  let exerciseDate = null
  if (location.state?.date) {
    exerciseDate = location.state.date
  }
  
  let backTo = '/exercise'
  if (location.state?.prevPage) {
    backTo = location.state.prevPage
  }
  if (location.state?.search) {
    backTo = location.state.search;
  } 

  async function submitPost() {

    if (formData.description === "" && postImage === null) {
      setError("É necessário adicionar uma imagem ou descrição")
      return
    }

    try {
      setStatus("submitting")
      
      const imageFormData = new FormData()
      imageFormData.append('exercise', postImage)

      let requestBody = null
      if (exerciseDate) {
        requestBody = {
          'description': formData.description,
          'date': exerciseDate //format(exerciseDate, "MM/dd/yyyy")
        }
      } else {
        requestBody = {
          'description': formData.description,
          'date': new Date()
        }
      }

      const data = await postRequestToApi(
        imageFormData, 
        `exercise/${cookies.get("userId")}`, 
        auth, 
        cookies, 
        true, 
        false, 
        JSON.stringify(requestBody)
      )

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
      
      if (postImage) {
        setHighlightedExercise({
          ...data, 
          created_at: requestBody.date,
          date: requestBody.date,
          hasImage: true,
          image: postImage
        })
      } else {
        setHighlightedExercise({
          ...data, 
          created_at: requestBody.date, 
          date: requestBody.date,
          hasImage: false, 
          image: null
        })
      }

    } catch (err) {
      console.log("Error submitting exercise")
      navigate(backTo, { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    } finally {
      //loadForumData(true) // true: user is loggedin
      //setRefreshFeed(true)
      setStatus("idle")
      navigate('/exercise/highlight', { state: { message: "Seu exercício físico foi submetida com sucesso, e em alguns instantes ele aparecerá nessa página" }})
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    //console.log('submitting')
    submitPost()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  function handleImageChange(e) {
    const target = e.target

    setPostImage(target.files[0])
  }


  React.useEffect(() => {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    }
  }, [])
  
  let exerciseHistoryFiltered = exerciseHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  exerciseHistoryFiltered = exerciseHistoryFiltered.sort((a, b) => new Date(b.date) - new Date(a.date)) // sorting array

  if (status !== "idle") {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Submetendo exercício...</p> 
      </div>
    )
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to={backTo}/>
      {(exerciseDate) ?
        <p className="heading3 top-margin-m"><strong>
          {`Adicionar Exercício Físico · ${format(exerciseDate, "dd/MM/yyyy")}`}
        </strong></p> :
        <p className="heading3 top-margin-m"><strong>Adicionar Exercício Físico</strong></p>
      }
      {(error) ?
      <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null }
      <form className="top-margin-s" onSubmit={handleSubmit}>
        
        <p className="body bottom-margin-xs">Tire foto da atividade que está fazendo, da folha com os exercícios da academia, ou adicione uma descrição</p>
        <div>
        <input type="file"
          className="bottom-margin-s"
          id="image-upload" 
          name="image" 
          accept="image/png, image/gif, image/jpeg, image/jpg" 
          onChange={handleImageChange}
          />
        </div>
        
        <textarea className="post-form top-margin-xs post-image-desc" 
            type="text" 
            name="description" 
            placeholder="Descrição (opcional): Ter os detalhes da sua atividade física pode ajudar no acompanhamento de sua evolução" 
            onChange={handleChange} 
            value={formData.description}/>
        
        <Button type="primary top-margin-s bottom-margin-m">{(status==="idle") ? "Enviar" : "Enviando..."}</Button>
      </form>

      <EditableItemsCard2 
        clsName="bottom-margin-l" 
        title="Seu histórico"
        icons={false}
      >
        <div>
          {exerciseHistoryFiltered.map((exercise, i) => {
            if (i < historySize) {
              return <ExerciseThumbnail key={exercise.id} exercise={exercise} icons={false}/>
            } else {
              return null
            }
          })}
          
          {(historySize < exerciseHistoryFiltered.length) ? 
            <Button type="tertiary left-margin-s top-margin-xs" onClick={()=>{ setHistorySize(prev=>prev+3)} }>Carregar mais</Button>
            : null
          }
        </div>
      </EditableItemsCard2>
    </div>
  )
}

//
