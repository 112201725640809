import React from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { getDataFromApi, getForumFeedFromApi, getProfilePostFromApi, getUserForumDataFromApi, getPostFromApi } from '../api'

const ForumContext = React.createContext()

export default function ForumData() {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const [loggedIn, setLoggedIn] = React.useState(false);
  
  const [refreshFeed, setRefreshFeed] = React.useState(false);

  const [forumData, setForumData] = React.useState({tokens: {}, posts: []});
  const [postGroup, setPostGroup] = React.useState(null)
  const [notifications, setNotifications] = React.useState([])
  const [relatedPosts, setRelatedPosts] = React.useState([])
  
  const [savedData, setSavedData] = React.useState({tokens: {}, posts: []});
  const [usefulData, setUsefulData] = React.useState({tokens: {}, posts: []});
  const [authoredData, setAuthoredData] = React.useState({tokens: {}, posts: []});
  const [commentedData, setCommentedData] = React.useState({tokens: {}, posts: []});

  const [postData, setPostData] = React.useState({});

  const [loading, setLoading] = React.useState(true)
  const [hasMore, setHasMore] = React.useState(false)

  const [profileForumData, setProfileForumData] = React.useState({tokens: {}, posts: []});
  const [profileLoading, setProfileLoading] = React.useState(true)
  const [profileHasMore, setProfileHasMore] = React.useState(true)
  //profileForumData, profileLoading, profileHasMore

  const [loadingUserData, setLoadingUserData] = React.useState(true)
  const [hasMoreUserData, setHasMoreUserData] = React.useState(false)
  
  const [forumSearchTerm, setForumSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(null);

  const [pageNumber, setPageNumber] = React.useState(0)
  const [postProfileIndex, setPostProfileIndex] = React.useState(0)

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    
    setPageNumber(0)
    setForumData(prev => {
      return {
        ...prev,
        posts: []
      }
    })


    if (!(cookies.get("userId"))) {
      setLoggedIn(false)
      loadForumData(false, 0)
    } else {
      setLoggedIn(true)
      loadForumData(true, 0)
    }

  }, [forumSearchTerm])

  React.useEffect(() => {
    setForumData(prev => ({tokens: prev.tokens, posts: []}))

    if (!(cookies.get("userId"))) {
      setLoggedIn(false)
      loadForumData(false, 0)

    } else {
      setLoggedIn(true)
      loadForumData(true, 0)

    }
  }, [postGroup])

  // if there is jwt tokens, but not userId cookies
  if (cookies.get("refreshToken")) {
    if (!(cookies.get("userId"))) {
      navigate('./family')
    }
  }

  async function loadUserForumData(dataName, pageNumber) {
    setLoadingUserData(true)

    if (pageNumber === undefined) {
      return
    }

    try {
      const feedData = await getUserForumDataFromApi(
        auth, cookies, pageNumber, {
        id: cookies.get("userId"),
        name: dataName
      })

      if (feedData.tokens.accessToken){
        setAuth(prev => ({...prev, ...feedData.tokens}))
      }

      if (dataName === 'myposts') {
        setAuthoredData(prev => ({posts: [...prev.posts, ...feedData.posts]}))
      } else if (dataName === 'saved_post') {
        setSavedData(prev => ({posts: [...prev.posts, ...feedData.posts]}))
      } else if (dataName === 'found_useful') {
        setUsefulData(prev => ({posts: [...prev.posts, ...feedData.posts]}))
      } else if (dataName === 'commented') {
        setCommentedData(prev => ({posts: [...prev.posts, ...feedData.posts]}))
      }

      setHasMoreUserData(feedData.posts.length > 0)
      setLoadingUserData(false)
    } catch (err) {
      console.log("Error loading forum data - get user forum data")
      //navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
      navigate('/forum')
    }
  }

  async function loadPost(postId) {

    try {
      const userId = cookies.get("userId")

      const post = await getPostFromApi(
        postId, auth, cookies, {
        id: userId 
      })

      setForumData(prev => {
        return {
          posts: [...prev.posts, post.post]
        }
      })
      //setPostData(prev => ({...prev, [postId]: post}))

    } catch (err) {
      console.log(err)
      console.log("Error loading forum data - load post")
      //navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
      navigate('/forum')
    }
  }

  async function loadProfilePost() {
    
    setProfileLoading(true)

    let feedData = null
    try {
      feedData = await getProfilePostFromApi(auth, cookies, +cookies.get("userId"))

      if (feedData.tokens.accessToken){
        setAuth(prev => ({...prev, ...feedData.tokens}))
      }

      setProfileForumData(prev => {
        return {
          posts: [...prev.posts, ...feedData.posts]
        }
      })

      setProfileHasMore(feedData.posts.length > 0)

      setProfileLoading(false)

    } catch (err) {
      console.log("Error loading forum data - load profile post")
      console.log(err)
      navigate(location.pathname)
    }
  //profileForumData, profileLoading, profileHasMore
    return
  }
  
  async function loadForumData(logginState, page) {
    try {
      setLoading(true)

      if (page === undefined) {
        return
      }

      let feedData = null
      if (forumSearchTerm === "") {
        feedData = await getForumFeedFromApi(
          auth, 
          cookies, 
          page, 
          {
            loggedIn: logginState, 
            setLogState: () => setLoggedIn(prev => !prev),
            id: cookies.get("userId")
          },
          postGroup
        )
      } else {
        //console.log(`filtering data: ${forumSearchTerm}`)
        feedData = await getForumFeedFromApi(
          auth, 
          cookies, 
          page, 
          {
            loggedIn: logginState, 
            setLogState: () => setLoggedIn(prev => !prev),
            id: cookies.get("userId")
          },
          postGroup,
          forumSearchTerm
        )
      }

      if (feedData.tokens.accessToken){
        setAuth(prev => ({...prev, ...feedData.tokens}))
      }

      setForumData(prev => {
        return {
          posts: [...prev.posts, ...feedData.posts]
        }
      })

      setHasMore(feedData.posts.length > 0)

      setLoading(false)
    } catch (err) {
      console.log("Error loading forum data - load forum data")
      console.log(err)
      //navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
      console.log(location)
      navigate(location.pathname)
    }
  }
  
  async function loadForumNotifications() {
    try {
      const response = await getDataFromApi(`forum/notifications/${cookies.get("userId")}`, 
        auth, cookies)
      setNotifications(response.notifications)

    } catch (err) {
      console.log("Error trying to load notifications from backend")
      console.log(err)
    }
  }

  async function getRelatedPosts(id) {

    try {
      const response = await getDataFromApi(`forum/related/${id}`, {}, cookies)

      setRelatedPosts(response.posts)

    } catch (err) {
      console.log("Error trying to load notifications from backend")
      console.log(err)
    }
  }


  // to use Effects, one for the first render, another for when things change
  React.useEffect(() => {

    cookies.remove('pageOfInterest', { path: '/' })
    
    if (!(cookies.get("userId"))) {
      setLoggedIn(false)
      loadForumData(false, 0)
      console.log('* user not logged in')
    } else {
      setLoggedIn(true)
      loadForumData(true, 0)
      console.log('* user logged in, loading data and notifications')
    
      // load notifications
      loadForumNotifications()
    }
    
    console.log("Fetching forum from API")
  }, [])


  return (
    <ForumContext.Provider value={{forumData, loadForumData, loading, hasMore, 
      loadingUserData, hasMoreUserData, setPostGroup,
      setForumData, loggedIn, loadForumData, setForumSearchTerm, forumSearchTerm,
      searchResults, loadUserForumData, savedData, usefulData, authoredData, commentedData, 
      loadPost, postData, setPostData, refreshFeed, setRefreshFeed, notifications, relatedPosts, getRelatedPosts,
      pageNumber, setPageNumber, postProfileIndex, setPostProfileIndex, 
      profileForumData, setProfileForumData, profileLoading, profileHasMore, loadProfilePost
    }}>
      <Outlet />
    </ForumContext.Provider>
  )
}

export { ForumContext }

