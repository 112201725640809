import React from 'react'
import { Link } from "react-router-dom"
import Button from './Button'
import Bulb from "../images/bulbDark.svg"
import HighlightedMessage from './HighlightedMessage'

export default function LargeButtonLink({mobileBreakpoint, addTo, addText, icon, title, text, to, message, clsName}) {

  return (
    <div className={(clsName) ? `large-button-link ${clsName}` : "large-button-link"}>
      {icon}
      <p className="body">{title}</p>
      {(message) ?
        <div>
          <HighlightedMessage icon={Bulb} type="message" header="Mensagem da Florence" body={message} />
        </div>
        : null } 
      <div>
        <Button type="primary top-margin-xs right-margin-xs bottom-margin-s" link={true} to={to}>Acessar</Button>
        {(addText) ? 
          <Button type="secondary right-margin-xs" link={true} to={addTo} stateValue="/profile">
            {addText}
          </Button>
          : null 
        }

      </div>
    </div>
  )
}
