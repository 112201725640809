import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import EatingHistory from "../components/EatingHistory"
import Return from "../components/Forum/Return"

import { deleteDataFromApi } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import Button from '../components/Button'

export default function UserExerciseHistory() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { exercise, exerciseHistory, setExerciseHistory,
    loadExerciseDataFromAPI, auth, cookies } = React.useContext(PortalContext);

  const navigate = useNavigate()
  const location = useLocation()
  
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/exercise', { path: '/' })
      navigate("/family")
    } else {
      loadExerciseDataFromAPI()
    }
  }, []);

  const exerciseHistoryFiltered = exerciseHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  
  const removeExercise = (id) => {
    console.log(`removing exercise ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'exercise', auth, cookies)
    setExerciseHistory(prev => prev.filter(item => item.id !== id))
  }
  
  return (
    (!exercise) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <Header tabletBreakpoint={tabletBreakpoint} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
        </div>

        <>
          <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

            <div className="top-margin-xl bottom-margin-xs">
              <Return to={`/profile`} backTo='Página inicial'/>
            </div>

            <p className="florence-title bottom-margin-l">Exercícios Físicos</p>

            <div className="bottom-margin-m">

              {(location.state?.message) ?
                <div className="bottom-margin-m">
                  <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={location.state.message} />
                </div>
                : null }

              <div>
                <Button type="primary" link={true} to="/exercise/submit">
                      Adicionar novo exercício
                </Button>
              </div>

              <div className="content-two-columns top-margin-m">
                <Link className="thumbnail-link" to="/exercise">
                  <p className="body">Acompanhamento</p>
                </Link>
                <Link className="thumbnail-link" to="/exercise/history">
                  <p className="body"><strong>Histórico</strong></p>
                </Link>
              </div>
              <div className="progress-bar bottom-margin-m">
                <div className="rectangle empty"></div>
                <div className="rectangle full"></div>
              </div>

            </div>

            <div className="bottom-margin-l">
              <EatingHistory 
                calendarItems={exerciseHistoryFiltered} 
                title={'Histórico de exercícios '} 
                tagged={false}
                grade={false}
                addItemtoArray={()=>{}}
                removeItemFromArray={removeExercise}
                type={"exercise"}
              />
            </div>

          </div>
        </>
      </>
      
  )
}
