import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Return from '../components/Forum/Return'
import { useNavigate } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { deleteDataFromApi } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import FoodThumbnail from '../components/Eating/FoodThumbnail'

export default function EatingHighlight() {
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const {
    highlightedMeal,
    setHighlightedMeal,
    eating,
    mealsToday, 
    mealsTodayLoading,
    setMealsToday,
    setMealsHistory,
    loadEatingDataFromAPI
  } = React.useContext(PortalContext);
  
  const navigate = useNavigate()

  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      loadEatingDataFromAPI()
    }
  }, []);

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const removeFood = (id) => {
    console.log(`removing food ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'food', auth, cookies)
    setMealsToday(prev => prev.filter(item => item.id !== id))
    setMealsHistory(prev => prev.filter(item => item.id !== id))
    setHighlightedMeal({error: true, error_message: "A refeição foi excluída com sucesso"})
  }
  
  const mealsFiltered = mealsToday.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to='/eating'/>

      <div className="top-margin-l">
        {(highlightedMeal.error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={highlightedMeal.error_message} /> :
          <EditableItemsCard2 
            clsName="bottom-margin-l" 
            title="Refeição adicionada"
            icons={false}
          >
            <div>
              <FoodThumbnail food={highlightedMeal} onRemove={() => removeFood(highlightedMeal.id)}/>
            </div> 
          </EditableItemsCard2>
        }
        
        <EditableItemsCard2 
          clsName="top-margin-l bottom-margin-l" 
          title="Sua alimentação hoje"
          icons={false}
        >
          <div>
            <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p className="body"><strong>Consumo de calorias: </strong>{Math.round(eating.calories)} cal</p> 
              </div>
            </div>
            <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p className="body"><strong>Consumo de proteínas: </strong>{Math.round(eating.proteins)} g</p> 
              </div>
            </div> 

            {mealsFiltered.map(food => <FoodThumbnail key={food.id} food={food} icons={false}/>)}


            {(eating.ids.length > mealsFiltered.length && mealsTodayLoading) ?
              <div className="icon-loading-middle-page top-margin-m">
                <p>Carregando suas refeições...</p> 
              </div> : null }
          </div>
        </EditableItemsCard2>
      </div>
  </div>
  )
}
