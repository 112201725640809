import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import EatingHistory from "../components/EatingHistory"
import PlotCard from "../components/PlotCard"
import Return from "../components/Forum/Return"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import EditableItemForCard2 from '../components/EditableItemForCard2'
import { postRequestToApi, putRequestToApi, deleteDataFromApi } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import { format, differenceInDays } from "date-fns"
import UserBmiCard from '../components/UserBmiCard'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import Bulb from "../images/bulbDark.svg"
import Button from '../components/Button'
import RemoveIcon from '../images/remove.svg'

import WeightLossForm from '../components/Eating/WeightLossForm'
import FoodThumbnail from '../components/Eating/FoodThumbnail'

export default function UserEatingHistory() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const { 
    eating, 
    mealsHistory,
    setMealsHistory,
    loadEatingDataFromAPI,
    auth, 
    cookies } = React.useContext(PortalContext);
  
  const navigate = useNavigate()
  const location = useLocation()
  
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      loadEatingDataFromAPI()
    }
  }, []);

  const removeFood = (id) => {
    console.log(`removing food ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'food', auth, cookies)
    setMealsHistory(prev => prev.filter(item => item.id !== id))
  }
  
  const mealsHistoryFiltered = mealsHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)

  return (
    (!eating) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <Header tabletBreakpoint={tabletBreakpoint} menuItems="portal" id={+cookies.get("userId")} menuShow={true}/>
        </div>

        <>
          <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

            <div className="top-margin-xl bottom-margin-xs">
              <Return to={`/profile`} backTo="Página inicial"/>
            </div>  

            <p className="florence-title bottom-margin-l">Alimentação</p>

            <div className="bottom-margin-m">

              {(location.state?.message) ?
                <div className="bottom-margin-m">
                  <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={location.state.message} />
                </div>
                : null }

              <div>
                <Button type="primary" link={true} to="/eating/submit">Adicionar nova refeição</Button>
              </div>

              <div className="content-two-columns top-margin-m">
                <Link className="thumbnail-link" to="/eating">
                  <p className="body">Acompanhamento</p>
                </Link>
                <Link className="thumbnail-link" to="/eating/history">
                  <p className="body"><strong>Histórico</strong></p>
                </Link>
              </div>

              <div className="progress-bar bottom-margin-m">
                <div className="rectangle empty"></div>
                <div className="rectangle full"></div>
              </div>

            </div>

            <div className="bottom-margin-l">
              <EatingHistory 
                calendarItems={mealsHistoryFiltered} 
                title={'Histórico de alimentação'} 
                tagged={false}
                grade={false}
                addItemtoArray={()=>{}}
                removeItemFromArray={removeFood}
                type={"eating"}
              />
            </div>

          </div>
        </>
      </>
  )
}
