import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import Tag from '../components/Tag'
import { ForumContext } from './ForumData'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { postRequestToApi } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import { v4 as uuidv4 } from 'uuid';

export default function Write() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { setRefreshFeed, loadForumData } = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [formData, setFormData] = React.useState({title: "", text: "", tag: ""})
  const [postImage, setPostImage] = React.useState()
  const [status, setStatus] = React.useState("idle")
  //const [tags, setTags] = React.useState([])
  const [error, setError] = React.useState(null)
  const [group, setGroup] = React.useState(null)

  const navigate = useNavigate()
  const location = useLocation()

  async function submitPost() {

    //if (formData.title === "" || formData.text === "") {
    //  setError("Seu post precisa conter título e texto")
    //  return
    //}
    if (formData.text === "") {
      setError("Seu post precisa conter um texto")
      return
    }

    let postImageId = null

    if (postImage) {
      postImageId = uuidv4();

      const formData = new FormData()
      formData.append('image', postImage)

      postRequestToApi(formData, 'forum/image', auth, cookies, true, false, postImageId)
    }

    let tag = []
    if (group !== null) {
      tag = [group]
    }

    try {
      setStatus("submitting")
      const data = await postRequestToApi({
        "id": cookies.get("userId"),
        "title": formData.title,
        "content": formData.text,
        "tag": tag,
        "post_image_uuid": postImageId
      }, "forum", auth, cookies)
      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      loadForumData(true) // true: user is loggedin
      setRefreshFeed(true)
      setStatus("idle")
      navigate('/forum')
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    //console.log('submitting')
    submitPost()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  function handleImageChange(e) {
    const target = e.target

    setPostImage(target.files[0])
  }

  //const addTag = (tag) => {
  //  if (tags.length === 5) {
  //    setError("Você pode selecionar no máximo 5 tags")
  //    setFormData(prev => ({...prev, tag: ""}))
  //    return
  //  }
  //  setTags(prev => ([...prev, tag]))
  //  setFormData(prev => ({...prev, tag: ""}))
  //}

  //const removeTag = (index) => {
  //  //console.log('remove tag')
  //  //console.log(index)
  //  setTags(prev => {
  //    prev.splice(index, 1)
  //    return [...prev]
  //  })
  //}

  React.useEffect(() => {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    }
  }, [])

  console.log(location)

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to={(location?.state?.search) ? `${location.state.search}` : '/forum'}/>
      <p className="heading3 top-margin-m"><strong>Fazer pergunta</strong></p>
      {(error) ?
      <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null }
      <form className="top-margin-s" onSubmit={handleSubmit}>
        <div className="bottom-margin-xs">
        <input className="form-post-title" type="text" name="title" onChange={handleChange}
          placeholder="Escolha um título para seu post (opcional)" value={formData.title}/>
        </div>
        <textarea className="post-form top-margin-xs" type="text" 
            name="text" placeholder="Digite seu texto" onChange={handleChange} value={formData.text}/>
        
        <p className="body top-margin-s bottom-margin-xs">Adicionar imagem</p>
        <input type="file" 
          id="image-upload" 
          name="image" 
          accept="image/png, image/gif, image/jpeg, image/jpg" 
          onChange={handleImageChange}/>
        
        <p className="body top-margin-s">Sobre qual assunto é seu post</p>
        <div className="tags-horizontal-menu top-margin-xs bottom-margin-s">
          <button type="button" className={(group === null) ? "tag-selected":"tag-filter"}
            onClick={()=>setGroup(null)}>Geral</button>
          <button type="button" className={(group === "diabetes") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setGroup("diabetes")}>Diabetes</button>
          <button type="button" className={(group === "pressao") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setGroup("pressao")}>Pressão alta</button>
          <button type="button" className={(group === "colesterol") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setGroup("colesterol")}>Colesterol alto</button>
          <button type="button" className={(group === "cancer") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setGroup("cancer")}>Câncer</button>
        </div>
        <Button type="primary top-margin-s bottom-margin-m">{(status==="idle") ? "Publicar" : "Publicando..."}</Button>
      </form>
    </div>
  )
}

//
