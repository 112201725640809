import React from 'react'
import { useNavigate } from "react-router-dom"
import EditableItemsCard2 from "../components/EditableItemsCard2"
import Calendar2 from "../components/Calendar2"
import Tag from "../components/Tag"
import Button from "../components/Button"
import { format } from "date-fns"
import RemoveIcon from '../images/remove.svg'
import ExerciseThumbnail from './Exercise/ExerciseThumbnail'
import FoodThumbnail from './Eating/FoodThumbnail'

export default function EatingHistory( {calendarItems, title, addItemtoArray, removeItemFromArray,
  tagged, grade=true, type='eating'} ) {

  // filter initially for today
  const [filter, setFilter] = React.useState(new Date)
  const [addCard, setAddCard] = React.useState(false)

  const [formData, setFormData] = React.useState({})

  const navigate = useNavigate()

  function handleChange(e) {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()

      const finalForm = formData

      if (Object.keys(finalForm).length === 0) {
        // if the object is empty, return it before adding date and tag
        addItemtoArray(finalForm)
        return
      }

      finalForm.date = new Date(filter)
      finalForm.id = null //calendarItems.length + 1
      if (grade) {
        finalForm.grade = "Em avaliação"
      }
      addItemtoArray(finalForm)
      setAddCard(false)
      setFormData({})
  }

  title += `- ${format(filter,"dd/MM/yyyy")}` 

  const onDayClick = (clickedDay) => {
    setFilter(clickedDay)
  }

  let accumulatedCalories = 0
  let accumulatedProteins = 0

  let calendarItemsAfterFilter = null
  if (calendarItems) {
    calendarItemsAfterFilter = calendarItems.filter(item => {
      const filterString = format(filter, "dd/MM/yyyy")
      return filterString.includes(format(item.date, "dd/MM/yyyy"))
    })
    if (type === "eating") {
      calendarItemsAfterFilter.forEach(item => {
        accumulatedCalories += +item.calories
        accumulatedProteins += +item.proteins
      })
    }
  }

  const addNewEntry = () => {
    if (type === "exercise") {
      navigate("/exercise/submit", {state: {date: filter, prevPage: '/exercise/history'}})
    } else if (type === "eating") {
      navigate("/eating/submit", {state: {date: filter, prevPage: '/eating/history'}})
    } else {
      setAddCard(prev=>!prev)
    }
  }

  const items = (calendarItemsAfterFilter) ? calendarItemsAfterFilter.map(item => {
   
    let tagColor = ''
    if (tagged) {
      tagColor = "blue"
      if (item.grade === "Atenção") {
        tagColor = "red"
      } else if (item.grade === "Super saudável") {
        tagColor = "green"
      }
    }

    if (type === "exercise") {
      return (
       <ExerciseThumbnail key={item.id} exercise={item} onRemove={() => removeItemFromArray(item.id)}/>
      )
    } else if (type === "eating") {
      return (
        <FoodThumbnail key={item.id} food={item} onRemove={() => removeItemFromArray(item.id)}/>
      )
    } else {
      return (
        <div key={item.id} className={(tagColor) ? 
          `editable-item-for-card-${tagColor} top-margin-xs vertical-padding-xs left-padding-s right-padding-xs`:
          "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"}>
          <div className='editable-item-for-card-header-center'>
            <div className="editable-item-and-tag"> 
              {(tagged) ? <Tag color={tagColor}>{item.grade}</Tag> : null}
              <p className="body vertical-margin-xxs">{item.description}</p>
              <p className="secondary-text">{format(item.date, "d/MM")}</p>
            </div>
            {(item.id) ? 
            <button onClick={()=>{removeItemFromArray(item.id)}} className='left-margin-xs carousel-btn'>
              <img className="" src={RemoveIcon}/>
            </button> : null}
          </div>
        </div>
      )
    }
  }) : null

  return (
    <div>
      <div className="container-simple vertical-padding-s calendar-history">
        <Calendar2 medicationReminders={calendarItems} dayClicked={filter} onDayClick={onDayClick} type={type}/>

        <EditableItemsCard2 title={title} icons={true} userAdd={true} addCard={()=>addNewEntry()}>

          {(addCard) ?

            <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
              <form onSubmit={handleSubmit}>
                <div className="vertical-margin-xs">
                  <textarea name="description" className="profile-question-form form-full-width shorter-form" 
                    onChange={handleChange} type="text" placeholder="Descrição do novo item"
                    value={(formData.description) ? formData.description : ''}/> 
                </div>
                <div>
                  <Button type="primary right-margin-s">Criar</Button>
                  <Button type="tertiary" onClick={()=>setAddCard()}>Cancelar</Button>
                </div>
              </form>
            </div>

          : null}

          {(type === 'eating') ? 
            <div>
              <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  <p className="body"><strong>Consumo de calorias: </strong>{Math.round(accumulatedCalories)} cal</p> 
                </div>
              </div>
              <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  <p className="body"><strong>Consumo de proteínas: </strong>{Math.round(accumulatedProteins)} g</p> 
                </div>
              </div>
            </div>
            : null 
          }

          {items}
        </EditableItemsCard2>

      </div>
    </div>
  )
}

