import React from 'react'
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Return from '../Forum/Return'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import EditableItemsCard2 from '../EditableItemsCard2'
import Button from '../Button'
import { postRequestToApi, putRequestToApi } from '../../api'
import HighlightedMessage from '../HighlightedMessage'
import Attention from "../../images/sun.svg"
import EditableItemForCard2 from '../EditableItemForCard2'
import UserBmiCard from '../UserBmiCard'

export default function HeartHealthObjective() {
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { 
    health,
    loadHealthDataFromAPI 
  } = React.useContext(PortalContext);
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [status, setStatus] = React.useState('idle')
  const [errorHeight, setErrorHeight] = React.useState(null)
  const [errorWeight, setErrorWeight] = React.useState(null)
  const [dataModified, setDataModified] = React.useState(0)
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null,
    smoking: "Não informado"
  })
  
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      //window.scrollTo(0, 0)
      loadHealthDataFromAPI(+cookies.get("userId"))
      //loadWeightDataFromAPI()
    }
  }, []);
  
  React.useEffect(() => {
    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        age: `${health[id].age}`,
        smoking: health[id].smoking_status ? `${health[id].smoking_status}` : '',
        organ_donation: health[id].organ_donation ? health[id].organ_donation : '',
        blood_type: health[id].organ_donation ? health[id].blood_type : '',
        sus_number: health[id].sus_number ? health[id].sus_number : '',
        emergency_contact: (health[id].emergency_contact.name)
          ? health[id].emergency_contact : {name: "", phone_number: null},
        insurance: health[id].insurance ? health[id].insurance : [],
        medication: (health[id].medication || health[id].medication.length===0 ) ? health[id].medication : [],
        allergy: (health[id].allergy || health[id].allergy.length===0 ) ? health[id].allergy : [],
        exam: (health[id].exams || health[id].disease.exams===0 ) ? 
          health[id].exams.map(exam => ({...exam, show: false}))  
          : [],
        prescription: (health[id].prescriptions) ? 
          health[id].prescriptions : {},
        vaccine: (health[id].vaccines) ? 
          health[id].vaccines : [],
        disease: (health[id].disease || health[id].disease.length===0 ) ? health[id].disease : [],
        nextAppointment: health[id].next_appointment ? `${health[id].next_appointment}` : '',
        height: health[id].height ? `${health[id].height}` : '',
        weight: health[id].weight ? `${health[id].weight}` : '',
        bmi: health[id].bmi ? `${health[id].bmi}` : null,
        healthHistory: (health[id].health_history || health[id].health_history.length===0 ) ? health[id].health_history : [],
        familyHealthHistory: (health[id].family_health_history || health[id].family_health_history.length===0 ) ? 
          health[id].family_health_history : [],
        medicationAdherence: (health[id].medication_adherence) ? 
          health[id].medication_adherence.map(item => ({
            id: item.id, date: new Date(item.date), frequency: item.frequency, medication: item.medication, status: item.status
        })) : []
      })

    }
  }, [health])
  
  React.useEffect(() => {
    if (dataModified > 0) {
      //console.log('user health changed, sending new data to API')

      const body = {
        "id": +cookies.get("userId"),
        "smoking_status": userHealth.smoking,
        "height": userHealth.height,
        "weight": userHealth.weight,
        "next_appointment": userHealth.nextAppointment,
        "sus_number": userHealth.sus_number,
        "blood_type": userHealth.blood_type,
        "organ_donation": userHealth.organ_donation,
        "emergency_contact": userHealth.emergency_contact
      }

      putRequestToApi(body, 'userhealth', auth, cookies) 
    }
  }, [dataModified])
  
  async function submitUpdatedData(bodyRequest) {
    try {
      setStatus("submitting")
      const data = await postRequestToApi(bodyRequest, '/food/goals', auth, cookies)

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      setStatus("idle")
      navigate('/eating')
    }
  }

  const addObjectives = () => {
    const requestBody = {
      'goalsArray': goals
    }

    submitUpdatedData({
      id: +cookies.get("userId"), 
      exam_data: requestBody
    })

    //navigate('/eating')
  }

  if (!health[+cookies.get("userId")]?.first_name) {
      return (
        <div className="icon-loading-middle-page">
          <img className="small-logo-nav loading-icon" 
              src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando página...</p> 
        </div>
      )
  }
  
  const editUserField = (name, value) => {

    // if value is empty, it does not enter the if statement
    if (value) {
      if (name === "height") {
        if (+value && +value < 3.0) {
          setErrorHeight(null)
          if (+userHealth.weight) {
            const bmi = userHealth.weight / (value * value)
            setUserHealth(prev => ({...prev, bmi: `${parseFloat(bmi).toFixed(1)}`}))
          }
        } else {
          setErrorHeight("Insira um número válido para a altura. Utilize ponto ao invés de vírgula. Por exemplo: 1.80")
          return
        }
      } else if (name === "weight") {
        if (+value && +value > 10 && +value < 250) {
          setErrorWeight(null)
          if (+userHealth.height) {
            const bmi = value / (userHealth.height * userHealth.height)
            setUserHealth(prev => ({...prev, bmi: `${parseFloat(bmi).toFixed(1)}`}))
          }
        } else {
          setErrorWeight("Insira um número válido para o peso. Utilize ponto ao invés de vírgula. Por exemplo: 70.5")
          return
        }
      }
      setUserHealth(prev => ({...prev, [name]: value}))
      setDataModified(prev => prev + 1)
    }

  }

  let healthyWeight = null
  if (+userHealth.bmi && +userHealth.height) {
    healthyWeight = parseFloat(25 * userHealth.height * userHealth.height).toFixed(1)
  }

  const userBmiCard = <UserBmiCard bmi={userHealth.bmi} simple={true} weight={true} healthyWeight={healthyWeight}/>

  const goals = [
    {
      "title": "",
      "content": "Comer pelo menos 3 frutas por dia",
      "manually_added": false,
    },
    {
      "title": "Consumir alimentos bons para o coração",
      "content": "Peixes, aves, feijão, lentilha, frutas, vegetais, castanhas, nozes, abacate, ervilha, alimentos integrais e ricos em fibra, azeite de oliva e óleo de canola",
      "manually_added": false,
    }, 
    {
      "title": "Evitar alimentos ruins para o coração",
      "content": "Comidas processadas, frituras, carne vermelha e alimentos com excesso de gordura saturada e gordura trans",
      "manually_added": false,
    }
  ]

  if (+userHealth.bmi && +userHealth.bmi > 25 && healthyWeight) {
    goals.push({
      'title': "Atingir meu peso saudável",
      'content': `até ${healthyWeight} kg`,
      'manually_added': false
    })
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to="/eating" />
      <p className="heading3 top-margin-m"><strong>Saúde do coração</strong></p>
      <p className="body top-margin-s bottom-margin-s">
        O primeiro passo para um coração e sistema cardiovascular saudável é 
        <strong> atingir seu peso saudável</strong>, 
        com índice de massa corporal (IMC) entre 18.5 e 25.
      </p>

      <EditableItemsCard2 
        clsName="bottom-margin-s"
        title="Medidas corporais"
        userAdd={false}
      >
        { (errorHeight) ?
          <div className="container-simple">
            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorHeight} />
          </div> : null
        }
        { (errorWeight) ?
          <div className="container-simple">
            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorWeight} />
          </div> : null
        }
        <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["height"]}
          placeholder={["Sua altura em metros"]}>
          <p className="body"><strong>Altura (m): </strong>{userHealth.height}</p>
        </EditableItemForCard2>
        <EditableItemForCard2 edit={true} editUserField={editUserField} userField={["weight"]}
          placeholder={["Seu peso em kg"]}>
          <p className="body"><strong>Peso (kg): </strong>{userHealth.weight}</p>
        </EditableItemForCard2>
        {userBmiCard}
      </EditableItemsCard2>
      
      <div>
        <p className="body top-margin-s">
          <strong>É importante saber a diferença entre gorguras boas e gorduras ruins, </strong> e substituir os alimentos com gorduras ruins por outros mais saudáveis
        </p>
        <p className="secondary-text top-margin-xs">
          Alimentos que contém gorduras boas: Peixes, aves, feijão, lentilha, frutas, vegetais, castanhas, nozes, abacate, ervilha, alimentos integrais e ricos em fibra, azeite de oliva e óleo de canola
        </p>
        <p className="secondary-text top-margin-xs bottom-margin-s">
          Alimentos para evitar ou eliminar da dieta: comidas processadas, frituras, carne vermelha e alimentos com excesso de gordura saturada e gordura trans 
        </p>
      </div>
        
      <EditableItemsCard2 
        clsName="bottom-margin-s" 
        title="Objetivos para controle do colesterol"
        icons={false}
      >
        {goals.map((item,i) => (
          <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
            <div className='editable-item-for-card-header'>
              {(item.title) ?
              <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p> : 
              <p key={i} className="body">{item.content}</p> 
              }
            </div>
          </div>
        ))}
      </EditableItemsCard2>

      <Button type="primary" onClick={()=>{addObjectives()}}>
        {(status==="idle") ? "Adicionar objetivos" : "Adicionando..." }
      </Button>
      
      <p className="secondary-text top-margin-s bottom-margin-xl">
        Atenção: Pessoas que se exercitam tem muito mais sucesso em manter seu peso ideal e em optar por uma alimentação saudável.
        Se você ainda não tem, desenvolva um plano de exercícios físicos
      </p>



    </div>
  )
}
